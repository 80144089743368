import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import { MdRemoveCircle } from "react-icons/md";
import { useSelector } from "react-redux";

const TableQuickdial = ({ user }) => {
  const softphoneUsers = useSelector((state) => state.softphoneUsers);
  const [dataQuick, setDataQuick] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const softphoneUsersByCompany = softphoneUsers.filter(
    (item) => item.company._id === user.company._id && item._id !== user._id
  );

  useEffect(() => {
    fetch(`https://api.nevtis.com/user/provisioning/quickDial/${user._id}`)
      .then((response) => response.json())
      .then((json) => {
        setDataQuick(json);
      })
      .catch((error) => console.error(error));
  }, [shouldUpdate, user._id]);

  async function toggleQuickDial(user_id, action) {
    setLoading(true);
    let url = "";
    if (action === "add") {
      url = `https://api.nevtis.com/user/provisioning/quickDial/add/${user._id}/newQuickdial/${user_id}`;
    } else {
      url = `https://api.nevtis.com/user/provisioning/quickDial/delete/${user._id}/toDelete/${user_id}`;
    }

    try {
      const response = await fetch(url, {
        method: action === "add" ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setLoading(false);
        setShouldUpdate(!shouldUpdate);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box maxHeight={"290px"} overflowY={"auto"}>
      <Table variant="simple" mt={5} boxShadow="xl" size={"sm"}>
        <Thead position="sticky" top={0} zIndex="sticky" bg="white">
          <Tr>
            <Th color={"rgba(255,134,0)"}>Name</Th>
            <Th color={"rgba(255,134,0)"}>Email</Th>
            <Th color={"rgba(255,134,0)"}>Ext</Th>
            <Th color={"rgba(255,134,0)"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {softphoneUsersByCompany.map((user) => {
            const isQuickDialItem =
              dataQuick &&
              dataQuick.find(
                (item) => item.uri === user.extension.extension.toString()
              );

            return (
              <Tr key={user._id}>
                <Td>
                  <Link to={`/softphone-user/${user._id}`}>
                    <Text color={"nevtis.1"} as={"u"}>
                      {user.name}
                    </Text>
                  </Link>
                </Td>
                <Td>{user.email}</Td>
                <Td>{user.extension.extension}</Td>

                <Td>
                  {isQuickDialItem ? (
                    <Tooltip
                      hasArrow
                      label="Remove from Quick Dial"
                      bg="red.600"
                    >
                      <IconButton
                        icon={
                          <MdRemoveCircle
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        }
                        size="sm"
                        isDisabled={loading}
                        onClick={() => toggleQuickDial(user._id, "remove")}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip hasArrow label="Add to Quick Dial" bg="green.500">
                      <IconButton
                        icon={
                          <IoIosAddCircle
                            style={{ fontSize: "20px", color: "green" }}
                          />
                        }
                        size="sm"
                        isDisabled={loading}
                        onClick={() => toggleQuickDial(user._id, "add")}
                      />
                    </Tooltip>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableQuickdial;
