import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  createPassRequest,
  resetPassRequest,
} from "../store/reducers/user.reducer";
import { useNavigate, useParams } from "react-router-dom";

export default function CreatePassword() {
  const { token, action } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { passwordValidate, password } = data;

    if (passwordValidate === password) {
      try {
        if (action === "create") {
          await handlePasswordCreation(password);
        } else {
          await handlePasswordReset(password);
        }
      } catch (error) {
        handlePasswordMismatchError();
      }
    } else {
      handlePasswordMismatchError();
    }
  };

  const handlePasswordReset = async (password) => {
    const resp = await dispatch(resetPassRequest({ password, token }));
    if (resp.payload.name) {
      showPasswordCreatedToast();
      navigate("/");
    } else {
      showPasswordErrorToast(resp.payload);
    }
  };

  const handlePasswordCreation = async (password) => {
    const resp = await dispatch(createPassRequest({ password, token }));
    if (resp.payload) {
      navigate("/");
      showPasswordCreatedToast();
    }
  };

  const handlePasswordMismatchError = () => {
    toast({
      title: "Password Not Match.",
      status: "error",
      duration: 15000,
      isClosable: true,
    });
  };
  const showPasswordErrorToast = (message) => {
    toast({
      title: message,
      status: "error",
      duration: 15000,
      isClosable: true,
    });
  };

  const showPasswordCreatedToast = () => {
    toast({
      title: "Password Created.",
      description: "You can sign in with your new password",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type="password" {...register("password")} />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Repeat Password</FormLabel>
                <Input type="password" {...register("passwordValidate")} />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  type="submit"
                  bg={"rgba(255,134,0)"}
                  color={"black"}
                  _hover={{
                    bg: "rgba(255,134,0,0.5)",
                  }}
                >
                  Create
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </form>
  );
}
