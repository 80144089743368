import { Box, Button } from "@chakra-ui/react";
import React from "react";

const Pagination = ({ sections, currentPage, handlePageChange }) => {
  return (
    <Box textAlign="center" width={"100%"} p={5} left={0} bottom={0} mt={20}>
      {sections.map((_, index) => (
        <Button
          backgroundColor={
            index + 1 === currentPage
              ? "rgba(255,134,0,0.3)"
              : "rgba(255,134,0)"
          }
          borderColor="rgba(255,134,0)"
          borderWidth={1}
          color={index + 1 === currentPage ? "white" : "black"}
          key={index}
          mx={1}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Button>
      ))}
    </Box>
  );
};

export default Pagination;
