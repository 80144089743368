import React from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const CardSoftphoneUsers = ({ path, avatar }) => {
  return (
    <Box py={2} bg="white">
      <Flex justifyContent="center" textAlign="center">
        <Text
          textAlign="center"
          mb={5}
          color={"gray.600"}
          fontFamily="mono"
          fontWeight={"bold"}
          mr={3}
        >
          My {path} ({avatar.length})
        </Text>
        <BsArrowRight color="nevtis.1" style={{ color: "nevtis.1" }} />
      </Flex>

      <Center>
        <AvatarGroup size="md" max={4}>
          {avatar.map((soft) => (
            <Tooltip label={soft.name} key={soft._id}>
              <Avatar
                name={soft.name}
                bg="nevtis.1"
                color="black"
                cursor="pointer"
              />
            </Tooltip>
          ))}
        </AvatarGroup>
      </Center>
    </Box>
  );
};

export default CardSoftphoneUsers;
