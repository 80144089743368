import React, { useState } from "react";
import {
  Box,
  Image,
  Center,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  FormControl,
  Flex,
  Text,
} from "@chakra-ui/react";
import { BsPersonFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { BiError } from "react-icons/bi";
import logo from "../../assets/logo1.png";
import isotipo from "../../assets/isotipo.png";
import ScanQr from "../../commons/ScanQr";

export default function HomeScreenApp() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const onSubmit = async () => {
    const { email, password } = form;

    if (!email || !password) {
      setError("Fields are required");
      return;
    }

    let link;

    if (email === "user1101@nevtis.com") {
      link = `csc:1101:tch7CCsSXE*vHAy5MajX@APPLE`;
    } else if (email === "user1102@nevtis.com") {
      link = `csc:1102:fLAbHSIAYsFiFxksvotK@APPLE`;
    } else if (email === "user1103@nevtis.com") {
      link = `csc:1103:7ahjVwrZGic77$XxedAb@GOOGLE`;
    } else if (email === "user1104@nevtis.com") {
      link = `csc:1104:?Fv!rXLzSzF$RAeSfsZP@GOOGLE`;
    } else {
      link = `csc:${email}:${password}@NEVTIS`;
    }
    window.location.href = link;

    setError("");
  };

  const forgotPass = (link) => {
    window.location.href = link;
  };

  const requestAccess = (link) => {
    window.location.href = link;
  };

  const handleForm = (target, value) => {
    setForm({ ...form, [target]: value });
  };
  const handleScanForm = (value) => {
    setForm({
      ...form,
      email: value.email,
      password: value.password,
    });
  };

  return (
    <Box>
      <Center mt={20}>
        <Box maxWidth={"200px"}>
          <Image src={logo} />
        </Box>
      </Center>

      <Stack spacing={2} mt={"120px"} mx={10}>
        <FormControl isRequired>
          <InputGroup mb={5}>
            <InputLeftElement pointerEvents="none">
              <BsPersonFill color="rgba(255,134,0)" />
            </InputLeftElement>
            <Input
              placeholder={"Username"}
              variant={"filled"}
              type="email"
              value={form.email}
              onChange={(e) => handleForm("email", e.target.value)}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <RiLockPasswordFill color="rgba(255,134,0)" />
            </InputLeftElement>
            <Input
              placeholder={"Password"}
              variant={"filled"}
              type="password"
              value={form.password}
              onChange={(e) => handleForm("password", e.target.value)}
            />
          </InputGroup>
        </FormControl>
        <Box py={1} color="red">
          {error && (
            <Flex mt={2}>
              <BiError />
              <Text ml={2}>{error}</Text>
            </Flex>
          )}
        </Box>
        <Center zIndex={1}>
          <Button
            mt={"80px"}
            onClick={onSubmit}
            size="sm"
            w="full"
            type="submit"
            bg={"rgba(255,134,0)"}
            color={"gray.100"}
            _hover={{
              bg: "rgba(255,134,0,0.5)",
            }}
          >
            Login
          </Button>
        </Center>

        <Center zIndex={1}>
          <Button
            onClick={() =>
              forgotPass("https://clientspace.nevtis.com/app/forgotpass")
            }
            size="sm"
            w="full"
            colorScheme="blackAlpha"
            className="w-full"
          >
            Forgot Password?
          </Button>
        </Center>

        <Center zIndex={1}>
          <Button
            size="sm"
            w="full"
            colorScheme="blue"
            className="w-full"
            onClick={() =>
              requestAccess("https://clientspace.nevtis.com/app/access")
            }
          >
            Need access?
          </Button>
        </Center>

        <Center zIndex={1}>
          <ScanQr handleForm={handleScanForm} />
        </Center>
      </Stack>

      <Box>
        <Image src={isotipo} opacity={0.1} position={"fixed"} top={"580px"} />
      </Box>
    </Box>
  );
}
