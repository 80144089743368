import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeSoftPhone from "../components/softphoneUsers/HomeSoftPhone";
import TableQuickdial from "../components/softphoneUsers/TableQuickdial";
import TableCalls from "../components/softphoneUsers/TableCalls";
const SoftphoneRoutes = ({ user }) => {
  return (
    <Routes>
      <Route path="/" element={<HomeSoftPhone user={user} />} />
      <Route path="/quickdial" element={<TableQuickdial user={user} />} />
      <Route path="/calls" element={<TableCalls user={user} />} />
    </Routes>
  );
};

export default SoftphoneRoutes;
