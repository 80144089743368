import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Text,
  Flex,
  Avatar,
  Box,
  AvatarBadge,
  Heading,
  Button,
  Spinner,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const TableSoftCallLives = ({ softphoneUsers, customer }) => {
  const [liveCalls, setLiveCalls] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const company = useSelector((state) => state.clients);
  const intervalIdRef = useRef(null);

  const getDomain = (domain) => {
    return company.filter((item) => item.domain.domain_name === domain);
  };

  const fetchLiveCalls = () => {
    axios
      .get("https://api.nevtis.com/fusionpbx/calls/live-calls")
      .then((res) => {
        setLiveCalls(res.data);
      })
      .catch((err) => {
        console.error("Error fetching live calls:", err);
      });
  };

  useEffect(() => {
    if (isFetching && !isPaused) {
      fetchLiveCalls();
      intervalIdRef.current = setInterval(fetchLiveCalls, 1000);
    }

    const pauseFetchAfterTimeout = () => {
      if (isFetching && !isPaused) {
        setIsPaused(true);
      }
    };

    const timeoutId = setTimeout(pauseFetchAfterTimeout, 120000);

    return () => {
      clearInterval(intervalIdRef.current);
      clearTimeout(timeoutId);
    };
  }, [isFetching, isPaused]);

  const handleToggleFetch = () => {
    if (isPaused) {
      setIsPaused(false);
      setIsFetching(true);
    } else {
      setIsPaused(true);
      clearInterval(intervalIdRef.current);
    }
  };

  const extractPBXURL = (call) => {
    if (call.b_presence_id) {
      const parts = call.b_presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.presence_id) {
      const parts = call.presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.accountcode) {
      return call.accountcode;
    } else {
      return "";
    }
  };

  const extractExtension = (call) => {
    if (call.b_presence_id) {
      const parts = call.b_presence_id.split("@");
      return parts.length > 1 ? parts[0] : "N/A";
    } else if (call.presence_id) {
      const parts = call.presence_id.split("@");
      return parts.length > 1 ? parts[0] : "N/A";
    } else if (call.accountcode) {
      return call.accountcode;
    } else {
      return "";
    }
  };

  const filteredLiveCalls = liveCalls.filter((call) => {
    const domain = extractPBXURL(call);
    return domain === customer?.domain.domain_name;
  });

  const sortedUsers = softphoneUsers.sort((a, b) => {
    const aOnCall = filteredLiveCalls.some(
      (call) => extractExtension(call) === a.extension.extension
    );
    const bOnCall = filteredLiveCalls.some(
      (call) => extractExtension(call) === b.extension.extension
    );
    return bOnCall - aOnCall;
  });

  return (
    <TableContainer px={4}>
      <Center>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <Heading textAlign={"center"} color={"nevtis.1"} my={4}>
            Call Status
          </Heading>

          <Box className={!isPaused && "loader"} boxSize={"20px"} mx={5} />
          <IconButton
            bg={"transparent"}
            size={"xs"}
            onClick={handleToggleFetch}
            icon={
              isPaused ? (
                <FaRegPlayCircle color="green" size={"25px"} />
              ) : (
                <FaRegCirclePause color="red" size={"25px"} />
              )
            }
          />
        </Flex>
      </Center>
      <Table size={"xs"} p={3}>
        <Thead>
          <Tr>
            <Th color={"black"} fontSize={"xs"}>
              Softphone User
            </Th>
            <Th color={"black"} fontSize={"xs"} textAlign={"center"}>
              Status
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedUsers.map((item) => {
            const isOnCall = filteredLiveCalls.some(
              (call) => extractExtension(call) === item.extension.extension
            );
            return (
              <Tr key={item._id} bg={isOnCall && "green.700"}>
                <Td>
                  <Link to={`/softphone-user/${item._id}`}>
                    <Flex py={2}>
                      <Avatar name={item?.name} bg={"nevtis.1"}>
                        <AvatarBadge
                          boxSize="0.9em"
                          bg={isOnCall ? "red" : "green"}
                        />
                      </Avatar>
                      <Text
                        ml={3}
                        justifyContent={"center"}
                        mt={4}
                        color={isOnCall ? "black" : "black"}
                        fontWeight={isOnCall && "bold"}
                      >
                        {item?.name}
                      </Text>
                    </Flex>
                  </Link>
                </Td>
                <Td textAlign={"center"}>
                  {isOnCall ? (
                    <Badge colorScheme="red">On Call</Badge>
                  ) : (
                    <Badge colorScheme="green">
                      Ext-{item.extension.extension}
                    </Badge>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {isFetching && !isPaused && (
        <Flex justifyContent="center" mt={4}>
          <Spinner size="xl" />
        </Flex>
      )}
    </TableContainer>
  );
};

export default TableSoftCallLives;
