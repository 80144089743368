import React from "react";
import { Link } from "react-router-dom";
import { Center, Flex, IconButton, Text } from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { AiOutlineSchedule } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { IoTicketOutline } from "react-icons/io5";
import { TfiServer } from "react-icons/tfi";
import { BsChatDots, BsTelephoneInbound } from "react-icons/bs";
import { PiAddressBookLight } from "react-icons/pi";
import { GoHome } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const path = useLocation().pathname.slice(1);
  const user = useSelector((state) => state.user);

  return (
    <>
      {path != "calls-details" && (
        <Flex
          mr={5}
          h="620px"
          pb={2}
          marginTop="2.2vh"
          boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
          borderTopRightRadius={"15px"}
          borderBottomRightRadius={"15px"}
          w={"75px"}
          flexDir="column"
          justifyContent="space-around"
        >
          <Flex p="5%" flexDir="column" alignItems={"center"} as="nav">
            <Link to={"/"}>
              <Center>
                <IconButton
                  background={path === "" ? "rgba(255,134,0,0.3)" : "none"}
                  mt={3}
                  fontSize={"3xl"}
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<GoHome color="nevtis.1" />}
                />
              </Center>

              <Text fontSize={12} textAlign="center" color="gray.500">
                Home
              </Text>
            </Link>

            {user.role === "company" ? (
              <>
                <Link to={"/softphoneUsers"}>
                  <Center>
                    <IconButton
                      background={
                        path === "softphoneUsers"
                          ? "rgba(255,134,0,0.3)"
                          : "none"
                      }
                      mt={5}
                      fontSize={"2xl"}
                      color="gray.500"
                      _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                      icon={<FiUsers />}
                    />
                  </Center>

                  <Text fontSize={12} textAlign="center" color="gray.500">
                    Softphones
                  </Text>
                </Link>

                <Link to={"/nevtisPbx"}>
                  <Center>
                    <IconButton
                      background={
                        path === "nevtisPbx" ? "rgba(255,134,0,0.3)" : "none"
                      }
                      mt={5}
                      fontSize={"2xl"}
                      color="gray.500"
                      _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                      icon={<TfiServer />}
                    />
                  </Center>

                  <Text fontSize={12} textAlign="center" color="gray.500">
                    Nevtis Pbx
                  </Text>
                </Link>
                <Link to={"/tickets"}>
                  <Center>
                    <IconButton
                      background={
                        path === "tickets" ? "rgba(255,134,0,0.3)" : "none"
                      }
                      mt={5}
                      fontSize={"3xl"}
                      color="gray.500"
                      _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                      icon={<IoTicketOutline />}
                    />
                  </Center>

                  <Text fontSize={12} textAlign="center" color="gray.500">
                    Tickets
                  </Text>
                </Link>
              </>
            ) : (
              <></>
            )}
            {user.role === "softphoneUser" && (
              <>
                <Link to={"/quickdial"}>
                  <Center>
                    <IconButton
                      background={
                        path === "quickdial" ? "rgba(255,134,0,0.3)" : "none"
                      }
                      mt={5}
                      fontSize={"3xl"}
                      color="gray.500"
                      _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                      icon={<PiAddressBookLight />}
                    />
                  </Center>

                  <Text fontSize={12} textAlign="center" color="gray.500">
                    QuickDial
                  </Text>
                </Link>
              </>
            )}
            <Link to={"/calls"}>
              <Center>
                <IconButton
                  background={path === "calls" ? "rgba(255,134,0,0.3)" : "none"}
                  mt={5}
                  fontSize={"2xl"}
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<BsTelephoneInbound />}
                />
              </Center>

              <Text fontSize={12} textAlign="center" color="gray.500">
                Calls
              </Text>
            </Link>
            <Link to="/activities" state={{ item: user }}>
              <Center>
                <IconButton
                  background={
                    path === "activities" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={5}
                  fontSize={"2xl"}
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<AiOutlineSchedule />}
                />
              </Center>

              <Text fontSize={12} textAlign="center" color="gray.500">
                Activities
              </Text>
            </Link>
            <Link to="/chat" state={{ item: user }}>
              <Center>
                <IconButton
                  background={path === "chat" ? "rgba(255,134,0,0.3)" : "none"}
                  mt={5}
                  fontSize={"2xl"}
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<BsChatDots />}
                />
              </Center>

              <Text fontSize={12} textAlign="center" color="gray.500">
                Chat
              </Text>
            </Link>
            <Link to={"/settings"}>
              <Center>
                <IconButton
                  background={
                    path === "settings" ? "rgba(255,134,0,0.3)" : "none"
                  }
                  mt={3}
                  fontSize={"2xl"}
                  color="gray.500"
                  _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
                  icon={<FiSettings />}
                />
              </Center>

              <Text fontSize={12} textAlign="center" color="gray.500">
                Settings
              </Text>
            </Link>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Sidebar;
