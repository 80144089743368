import React from "react";
import { Box, VStack, HStack, Icon, Text, Center } from "@chakra-ui/react";
import { FaArrowDown, FaArrowUp, FaClock } from "react-icons/fa";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const CardStatitics = ({ title, count, icon, call, link, softphoneUsers }) => {
  const calculateCallDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, " h:mm:ss a");
  };

  const getSoft = (ext) => {
    return softphoneUsers?.find((soft) => ext === soft.extension.extension);
  };

  return (
    <Center>
      <Link to={`/${link}`}>
        <Box
          bg={"gray.200"}
          justifyContent="center"
          alignItems="center"
          color="white"
          textAlign="center"
          borderRadius={"2xl"}
          width={"200px"}
          height={"150px"}
          py={2}
        >
          <VStack>
            {!call && <Icon as={icon} w={10} h={10} color={"black"} />}
            <Text fontSize="2xl" fontWeight="bold" color={"black"}>
              {count}
            </Text>
            {call && (
              <Box key={call.uuid} p={2} maxW="sm">
                <HStack mb={2} justify="space-between">
                  <Icon
                    as={call.direction === "inbound" ? FaArrowDown : FaArrowUp}
                    color={
                      call.direction === "inbound" ? "green.500" : "blue.500"
                    }
                    boxSize={8}
                  />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold" color={"black"}>
                      {call.caller_destination}
                    </Text>
                    {call.direction === "inbound" ? (
                      <Text fontSize="sm" color="black">
                        {call.destination_number}
                      </Text>
                    ) : (
                      <>
                        {getSoft(call.ext)?.name ? (
                          <Text fontSize="sm" color="black">
                            {getSoft(call.ext).name}
                          </Text>
                        ) : (
                          <Text fontSize="sm" color="black">
                            -
                          </Text>
                        )}
                      </>
                    )}
                  </VStack>
                </HStack>

                <HStack spacing={1} justify="space-between">
                  <HStack spacing={1}>
                    <Text fontSize="sm" color={"black"}>
                      {formattedDate(call.end_stamp)}
                    </Text>
                  </HStack>
                  <HStack spacing={1}>
                    <Icon as={FaClock} color="orange.500" boxSize={4} />
                    <Text fontSize="sm" color={"black"}>
                      {calculateCallDuration(call.duration)}
                    </Text>
                  </HStack>
                </HStack>
              </Box>
            )}
            <Text fontSize="xl" fontWeight={"bold"} color={"black"}>
              {title}
            </Text>
          </VStack>
        </Box>
      </Link>
    </Center>
  );
};

export default CardStatitics;
