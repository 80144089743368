import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";
import CreateRingGroup from "./CreateRingGroup";

const TableRingGroups = ({ ringGroup }) => {
  return (
    <Box>
      <CreateRingGroup />
      <TableContainer mt={10} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Extension
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {ringGroup.map((ring) => (
              <Tr key={ring.ring_group_uuid}>
                <Td textAlign={"center"}>{ring.ring_group_extension}</Td>
                <Td textAlign={"center"}>{ring.ring_group_name}</Td>
                <Td textAlign={"center"}>{ring.ring_group_description}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableRingGroups;
