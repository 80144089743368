import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Select,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateDestination = ({
  extensions,
  ringGroup,
  faxServers,
  ivrMenus,
  voicemail,
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    destination: "",
    callerIdName: "",
    callerIdNumber: "",
    description: "",
    actions: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    for (const key in formData) {
      if (formData[key] === "") {
        setError(`Please complete all fields`);
        return;
      }
    }

    const resp = await dispatch();
    if (resp) {
      setFormData({
        destination: "",
        callerIdName: "",
        callerIdNumber: "",
        description: "",
        actions: "",
      });
      onClose();
      showToast("Extension created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={10}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            mb={10}
          >
            Create Destinations
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Destination</FormLabel>
                    <Input
                      name="destination"
                      value={formData.destination}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Caller ID Name</FormLabel>
                    <Input
                      name="callerIdName"
                      value={formData.callerIdName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Caller ID Number
                    </FormLabel>
                    <Input
                      name="callerIdNumber"
                      value={formData.callerIdNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Actions</FormLabel>
                  <Select
                    name="actions"
                    onChange={handleInputChange}
                    value={formData.actions}
                  >
                    <option value="">Select an Action</option>
                    <optgroup label="Extensions">
                      {extensions.map((extension, index) => (
                        <option key={index} value={extension}>
                          {extension.extension_number} -{" "}
                          {extension.effective_caller_id_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Ring Groups">
                      {ringGroup.map((group, index) => (
                        <option key={index} value={group}>
                          {group.ring_group_extension} - {group.ring_group_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Fax Servers">
                      {faxServers.map((fax, index) => (
                        <option key={index} value={fax}>
                          {fax.fax_destination_number} - {fax.fax_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="IVR Menus">
                      {ivrMenus.map((menu, index) => (
                        <option key={index} value={menu}>
                          {menu.ivr_menu_extension} - {menu.ivr_menu_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Voicemail">
                      {voicemail.map((mail, index) => (
                        <option key={index} value={mail}>
                          {mail.voicemail_id} - {mail.voicemail_description}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                </FormControl>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateDestination;
