import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import CreateIVR from "./CreateIVR";

const TableIVR = ({
  data,
  extensionesAsignadas,
  ringGroup,
  faxServers,
  voicemail,
}) => {
  console.log(data);
  return (
    <Box>
      <CreateIVR
        extensionesAsignadas={extensionesAsignadas}
        ringGroup={ringGroup}
        faxServers={faxServers}
        voicemail={voicemail}
      />
      <TableContainer mt={10} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Extension
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Enabled
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((ivr) => (
              <Tr key={ivr.dialplan_uuid}>
                <Td textAlign={"center"}>{ivr.ivr_menu_name}</Td>
                <Td textAlign={"center"}>{ivr.ivr_menu_extension}</Td>
                <Td textAlign={"center"}>
                  <Badge colorScheme="green">{ivr.ivr_menu_enabled}</Badge>
                </Td>
                <Td textAlign={"center"}>{ivr.ivr_menu_description}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableIVR;
