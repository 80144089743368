import React from "react";
import { Box } from "@chakra-ui/react";
import nevtisLogo from "../assets/logo.png"; // Asegúrate de reemplazar esto con la ruta correcta al archivo de imagen de tu logo

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <img
        src={nevtisLogo}
        alt="Nevtis Logo"
        width="200"
        height="200"
      />
    </Box>
  );
};

export default Loading;
