import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  Center,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/logo1.png";
import avatarReal from "../assets/userO.png";
import { useSelector, useDispatch } from "react-redux";
import { logoutRequest } from "../store/reducers/user.reducer.js";
import MenuMobile from "../commons/MenuMobile";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const path = useLocation().pathname.slice(1);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    const resp = await dispatch(logoutRequest());
    if (resp) navigate("/");
  };
  return (
    <>
      {path != "calls-details" && (
        <Box px={4} boxShadow="xl">
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <Link to={"/"}>
              <Box>
                <Image src={logo} height="12" p={2} />
              </Box>
            </Link>
            <Flex alignItems={"center"} zIndex={2}>
              <Stack direction={"row"} spacing={7}>
                {user && isDesktop ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <Avatar size={"md"} src={avatarReal} />
                    </MenuButton>
                    <MenuList alignItems={"center"}>
                      <br />
                      <Center>
                        <Avatar size={"2xl"} src={avatarReal} />
                      </Center>
                      <br />
                      <Center>
                        {user.role === "company" ? (
                          <p>{user.companyName}</p>
                        ) : (
                          <p>{user.name || user.contact}</p>
                        )}
                      </Center>
                      <br />
                      <MenuDivider />
                      <Link to={"/settings"}>
                        <MenuItem>Your Account</MenuItem>
                      </Link>
                      <MenuItem onClick={logout}>Logout</MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <MenuMobile user={user} logout={logout} />
                )}
              </Stack>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
}
