import React from "react";
import { Box, Text, Flex, Icon } from "@chakra-ui/react";
import {
  MdAccountCircle,
  MdPhone,
  MdExtension,
  MdSettings,
} from "react-icons/md";

const DashboardInfoBox = ({ user }) => {
  return (
    <Box>
      <Flex align="center" mt={4}>
        <Icon as={MdAccountCircle} color="nevtis.1" boxSize={6} mr={3} />
        <Flex direction="column">
          <Text fontWeight="bold" color={"gray.600"}>
            Title Nevtis App
          </Text>
          <Text>{user.provisionning.account.title}</Text>
        </Flex>
      </Flex>
      <Flex align="center" mt={4}>
        <Icon as={MdPhone} color="nevtis.1" boxSize={6} mr={3} />
        <Flex direction="column">
          <Text fontWeight="bold" color={"gray.600"}>
            Caller ID Name
          </Text>
          <Text>{user.extension.effective_caller_id_name}</Text>
        </Flex>
      </Flex>
      <Flex align="center" mt={4}>
        <Icon as={MdExtension} color="nevtis.1" boxSize={6} mr={3} />
        <Flex direction="column">
          <Text fontWeight="bold" color={"gray.600"}>
            Extension
          </Text>
          <Text>{user.extension.extension}</Text>
        </Flex>
      </Flex>
      <Flex align="center" mt={4}>
        <Icon as={MdSettings} color="nevtis.1" boxSize={6} mr={3} />
        <Flex direction="column">
          <Text fontWeight="bold" color={"gray.600"}>
            Outbound Caller ID Name
          </Text>
          <Text>{user.extension.outbound_caller_id_name}</Text>
        </Flex>
      </Flex>
      <Flex align="center" mt={4}>
        <Icon as={MdSettings} color="nevtis.1" boxSize={6} mr={3} />
        <Flex direction="column">
          <Text fontWeight="bold" color={"gray.600"}>
            Outbound Caller ID Number
          </Text>
          <Text>{user.extension.outbound_caller_id_number}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DashboardInfoBox;
