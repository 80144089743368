import React from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Link as LinkChakra,
  TabPanel,
  Box,
  Heading,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Button,
  Text,
} from "@chakra-ui/react";
import Loading from "../../../commons/Loading";
import { useSelector } from "react-redux";
import { IoSettingsSharp } from "react-icons/io5";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import Activities from "../../Actions/Activities";
import SoftphoneDetail from "./SoftphoneDetail";
import EditSoftphone from "./EditSoftphone";
import DeleteSoftphone from "./DeleteSoftphone";
import axios from "axios";
import { showToast } from "../../../commons/SweetAlertToast";

const SoftphoneDetailsTabs = () => {
  const userLocalStorg = JSON.parse(localStorage.getItem("user"));
  const master = userLocalStorg.token;
  const { softphoneId } = useParams();
  const user = useSelector((state) => state.user);
  const allSoftphone = useSelector((state) => state.softphoneUsers);
  const softphone = allSoftphone.find((item) => item._id === softphoneId);
  const sendAccessCode = (user) => {
    if (!user.email) {
      return;
    }
    axios
      .post("https://api.nevtis.com/comunication/email/access-code", {
        email: user.email,
        token: user.activationCode,
        name: user.name,
        role: user.role,
      })
      .then((res) => {
        res.status === 200
          ? showToast("Access Code Sent", "success")
          : showToast("Error", "error");
      });
  };

  if (!softphone) {
    return <Loading />;
  }

  return (
    <Tabs mt={10} width="100%">
      <Center>
        <Flex>
          <Heading
            fontSize="4xl"
            fontWeight="bold"
            textAlign="center"
            mr="4"
            color={"nevtis.1"}
          >
            {softphone.name}
          </Heading>

          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<IoSettingsSharp />}
              variant="outline"
            />
            <MenuList>
              <EditSoftphone user={softphone} />
              <DeleteSoftphone user={softphone} />
            </MenuList>
          </Menu>
        </Flex>
      </Center>
      <Center mb={4}>
        {softphone.isActivated ? (
          <Flex>
            <Text
              size={"xs"}
              color="green"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCheckCircle style={{ marginRight: "2px" }} /> Active
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text
              size={"xs"}
              color="red"
              borderRadius="md"
              px="2"
              py="2"
              display={"flex"}
            >
              <MdCancel style={{ marginRight: "4px" }} />
              Inactive
            </Text>
          </Flex>
        )}
        {!softphone.isActivated && (
          <Button
            size={"xs"}
            textAlign={"center"}
            bg={"nevtis.1.4"}
            _hover={{ bg: "nevtis.1" }}
            onClick={() => sendAccessCode(softphone)}
          >
            <RiMailSendLine style={{ marginRight: "2px" }} />
            Access Code
          </Button>
        )}
      </Center>
      <TabList>
        <Tab>Details</Tab>
        <Tab>Activities</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box width="100%">
            <SoftphoneDetail user={softphone} />
          </Box>
        </TabPanel>

        <TabPanel>
          <Box width="100%">
            <Activities user={softphone} />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SoftphoneDetailsTabs;
