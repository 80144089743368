import React from "react";
import { Box, VStack, HStack, Icon, Text, Center } from "@chakra-ui/react";
import { FaTools } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

const InConstruction = ({ title }) => {
  return (
    <Center height="80vh" width={"100%"}>
      <Box
        bgGradient="linear(to-r, teal.400, rgba(255,134,0,1), blue.500)"
        justifyContent="center"
        alignItems="center"
        color="white"
        textAlign="center"
        p={20}
        borderRadius={"2xl"}
      >
        <VStack spacing={6}>
          <Icon as={FaTools} w={16} h={16} />
          <Text fontSize="4xl" fontWeight="bold">
            {title} under construction
          </Text>
          <Text fontSize="xl">
            We are working hard to bring you a new experience!
          </Text>

          <HStack spacing={3}>
            <Icon as={AiOutlineMail} w={6} h={6} />
            <Text fontSize="lg">Contact us: support@nevtis.com</Text>
          </HStack>
        </VStack>
      </Box>
    </Center>
  );
};

export default InConstruction;
