import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";
import CreateFaxServer from "./CreateFaxServer";

const TableFaxServer = ({ data }) => {
  return (
    <Box>
      <CreateFaxServer />
      <TableContainer mt={10} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Extension
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Email
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((fax) => (
              <Tr key={fax.dialplan_uuid}>
                <Td textAlign={"center"}>{fax.fax_name}</Td>
                <Td textAlign={"center"}>{fax.fax_extension}</Td>
                <Td textAlign={"center"}>{fax.fax_email}</Td>
                <Td textAlign={"center"}>{fax.fax_description}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableFaxServer;
