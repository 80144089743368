import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import avatar from "../../assets/profile.svg";
import PotentialChats from "./PotentialChats";
import { FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFetchRecipient } from "./useFetchRecipient";

const ChatNavbar = () => {
  const user = useSelector((state) => state.user);
  const chats = useSelector((state) => state.chats.chats);
  const currentChat = useSelector((state) => state.chats.currentChat);
  const { recipientUser } = useFetchRecipient(currentChat, user);

  const path = {
    company: "client",
    softphoneUser: "softphone-user",
    admin: "admin",
    seller: "seller",
  };
  return (
    <Center bg={"gray.400"} py={2} borderRadius={"md"}>
      <PotentialChats user={user} chats={chats} />
      <Flex textAlign={"center"}>
        <Flex>
          {!recipientUser ? (
            <Text textAlign={"center"} ml={5}>
              Please select a chat or look for people
            </Text>
          ) : (
            <>
              <Flex
                bg={"nevtis.1.1"}
                ml={10}
                py={1}
                borderRadius={"xl"}
                px={5}
                border={"1px solid rgba(255,134,0,0.6)"}
                boxShadow={"lg"}
              >
                <Link to={`/${path[recipientUser.role]}/${recipientUser._id}`}>
                  <Flex>
                    <Box boxSize="30px" mr={2}>
                      <Image src={avatar} />
                    </Box>
                    <Text
                      color={"gray.700"}
                      as={"u"}
                      mr={10}
                      mt={1}
                      fontWeight={"bold"}
                    >
                      {recipientUser.role === "company"
                        ? recipientUser.companyName.toUpperCase()
                        : recipientUser.name.toUpperCase()}
                    </Text>
                  </Flex>
                </Link>
                <FaPhone style={{ marginTop: "8px", marginRight: "4px" }} />
                <Text mt={1}>{recipientUser?.phone}</Text>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Center>
  );
};

export default ChatNavbar;
