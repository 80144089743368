import axios from "axios";
import { useEffect, useState } from "react";

export const useFetchRecipient = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [error, setError] = useState(null);

  const recipientId = chat?.members.find((id) => id !== user._id);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!recipientId) return;
        const resp = await axios.get(
          `https://api.nevtis.com/user/users/user/${recipientId}`
        );
        setRecipientUser(resp.data);
      } catch (error) {
        setError(error);
      }
    };
    getUser();
  }, [recipientId]);

  return { recipientUser, error };
};
