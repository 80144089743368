import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Button,
} from "@chakra-ui/react";
import React from "react";
import CreateIVR from "./CreateIVR";
import TicketStatus from "../../../commons/TicketStatus";

const TableTickets = ({ data }) => {
  return (
    <Box>
      <TableContainer mt={10} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Type
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Date
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Status
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td textAlign={"center"}>Destination</Td>
              <Td textAlign={"center"}>07-12-2024</Td>
              <Td textAlign={"center"}>Remove 7145823455</Td>
              <Td textAlign={"center"}>
                <TicketStatus status="Open" />
                <Button ml={4} size={"xs"} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
            <Tr>
              <Td textAlign={"center"}>Extension</Td>
              <Td textAlign={"center"}>17-11-2024</Td>
              <Td textAlign={"center"}>Add ext 102</Td>
              <Td textAlign={"center"}>
                <TicketStatus status="New" />
                <Button ml={4} size={"xs"} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
            <Tr>
              <Td textAlign={"center"}>Ring Group</Td>
              <Td textAlign={"center"}>04-04-2024</Td>
              <Td textAlign={"center"}>
                Include extension 140 to ring group 1005
              </Td>
              <Td textAlign={"center"}>
                <TicketStatus status="Escalated" />
                <Button ml={4} size={"xs"} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
            <Tr>
              <Td textAlign={"center"}>Fax Server</Td>
              <Td textAlign={"center"}>04-01-2024</Td>
              <Td textAlign={"center"}>
              Add a fax for the email test@test.com
              </Td>
              <Td textAlign={"center"}>
                <TicketStatus status="Resolved" />
                <Button ml={4} size={"xs"} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
            <Tr>
              <Td textAlign={"center"}>Ivr Menu</Td>
              <Td textAlign={"center"}>04-07-2024</Td>
              <Td textAlign={"center"}>
               Remove Billing
              </Td>
              <Td textAlign={"center"}>
                <TicketStatus status="Unresolved" />
                <Button ml={4} size={"xs"} colorScheme="red">
                  Delete
                </Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableTickets;
