import React, { useEffect, useState } from "react";
import TableCustomerCalls from "./TableCustomerCalls";
import LiveCalls from "./LiveCalls";
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import axios from "axios";
const TabCalls = ({ customer }) => {
  const [calls, setCalls] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByDomain/${customer.domain.domain_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);

  return (
    <Box mt={20} width={"100%"}>
      <Heading color={"nevtis.1"} textAlign={"center"} mb={10}>
        Calls
      </Heading>
      <Tabs variant="soft-rounded" colorScheme="orange" mt={4}>
        <TabList>
          <Tab>Recorded Calls</Tab>
          <Tab>Live Calls</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box mt={10}>
              <TableCustomerCalls calls={calls} customer={customer} />
            </Box>
          </TabPanel>
          <TabPanel>
            <LiveCalls customer={customer} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabCalls;
