import React from "react";
import { Route, Routes } from "react-router-dom";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import Settings from "../components/Settings";
import ClientRoutes from "./ClientRoutes";
import SoftphoneRoutes from "./SoftphoneRoutes";
import TableCalls from "../components/softphoneUsers/TableCalls";
import Activities from "../components/Actions/Activities";
import HomeChat from "../components/chat/HomeChat";

const UserActive = ({ user }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  return (
    <>
      <Flex>
        {isDesktop && <Sidebar />}
        <Routes>
          <Route path="/settings" element={<Settings />} />
          <Route path="/activities" element={<Activities user={user} />} />
          <Route path="/chat" element={<HomeChat />} />
        </Routes>
        {user.role === "company" && <ClientRoutes />}
        {user.role === "softphoneUser" && <SoftphoneRoutes user={user} />}
      </Flex>
    </>
  );
};

export default UserActive;
