import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CardStatitics from "../../commons/CardStatitics";
import { MdContactPhone } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BiSolidBellRing } from "react-icons/bi";
import CallsByExtGraph from "./callsDetails/CallsByExtGraph";
import TableSoftCallLives from "./fusionpbx/TableSoftCallLives";

const HomeCustomer = ({ user }) => {
  const [destinations, setDestinations] = useState([]);
  const [ringGroup, setRingGroup] = useState([]);
  const [calls, setCalls] = useState([]);
  const [error, setError] = useState([]);
  const allSoftphoneUsers = useSelector((state) => state.softphoneUsers);
  const softphoneUsers = allSoftphoneUsers.filter(
    (item) => item.company._id === user._id
  );

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByDomain/${user.domain.domain_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [user.domain.domain_uuid]);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/destinations/getAllDestinationsByDomain/${user.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setDestinations(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [user.domain.domain_uuid]);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/ring-groups/getAllRingGroupsByDomain/${user.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setRingGroup(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [user.domain.domain_uuid]);

  const callsActive = calls.filter((call) => call.duration !== null);

  return (
    <Box>
      <Flex
        px={10}
        mt={"130px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          width={"500px"}
          bg={"gray.200"}
          borderRadius={"2xl"}
          maxHeight={"475px"}
          overflowY="scroll"
          mt={5}
          ml={10}
        >
          <TableSoftCallLives softphoneUsers={softphoneUsers} customer={user} />
        </Box>

        <VStack spacing={"80px"} mx={14}>
          <HStack spacing={10}>
            <CardStatitics
              title={"Softphone Users"}
              count={softphoneUsers.length}
              icon={MdContactPhone}
              link={"softphoneUsers"}
            />
            <CardStatitics
              title={"Destinations"}
              count={destinations.length}
              icon={FaPhoneVolume}
              link={"nevtisPbx"}
            />
          </HStack>
          <HStack spacing={10}>
            <CardStatitics
              title={"Ring Groups"}
              count={ringGroup.length}
              icon={BiSolidBellRing}
              link={"nevtisPbx"}
            />
            {callsActive.length > 0 && (
              <CardStatitics
                softphoneUsers={softphoneUsers}
                title={"Last Call"}
                icon={MdContactPhone}
                call={callsActive[0]}
                link={"calls"}
              />
            )}
          </HStack>
        </VStack>
        <Box flex="1">
          <CallsByExtGraph softphoneUsers={softphoneUsers} />
        </Box>
      </Flex>
    </Box>
  );
};

export default HomeCustomer;
