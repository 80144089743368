import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
  Switch,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateRingGroup = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    extension: "",
    description: "",
    destination: [
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
      {
        destination: "",
        delay: "0",
        timeout: "30",
        prompt: "",
        enable: false,
        delete: false,
      },
    ],
  });
  const handleInputChange = (e, index, field) => {
    const { value, type, checked } = e.target;
    setFormData((prevFormData) => {
      const newDestination = [...prevFormData.destination];
      if (type === "checkbox") {
        newDestination[index][field] = checked;
      } else {
        newDestination[index][field] = value;
        if (field === "destination") {
          newDestination[index]["enable"] = value.trim() !== "";
        }
      }
      return { ...prevFormData, destination: newDestination };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    if (formData.name === "" || formData.extension === "") {
      setError(`Please complete all fields`);
      return;
    }

    console.log(formData);
    /*     const resp = await dispatch();
    if (resp) {
      setFormData({
        name: "",
        extension: "",
        description: "",
        destination: [
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
          {
            destination: "",
            delay: "0",
            timeout: "30",
            prompt: "",
            enable: false,
            delete: false,
          },
        ],
      });
      onClose();
      showToast("Extension created", "success");
    } else {
      showToast("Error", "error");
    } */
  };

  const renderNumberOptions = (start, end, step) => {
    const options = [];
    for (let i = start; i <= end; i += step) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={3}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
          >
            Create Ring Group
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Extension</FormLabel>
                  <Input
                    name="extension"
                    value={formData.extension}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormLabel style={formLabelStyle}>Destinations</FormLabel>
                <Stack spacing={4}>
                  {formData.destination.map((desc, index) => (
                    <Stack direction="row" spacing={2} key={index}>
                      <Input
                        name={`destination-${index}`}
                        placeholder="Destination"
                        value={desc.destination}
                        onChange={(e) =>
                          handleInputChange(e, index, "destination")
                        }
                      />
                      <Select
                        name={`delay-${index}`}
                        value={desc.delay}
                        onChange={(e) => handleInputChange(e, index, "delay")}
                      >
                        {renderNumberOptions(0, 1000, 5)}
                      </Select>
                      <Select
                        name={`timeout-${index}`}
                        value={desc.timeout}
                        onChange={(e) => handleInputChange(e, index, "timeout")}
                      >
                        {renderNumberOptions(5, 995, 5)}
                      </Select>
                      <Select
                        name={`prompt-${index}`}
                        value={desc.prompt}
                        onChange={(e) => handleInputChange(e, index, "prompt")}
                      >
                        <option value=""></option>
                        <option value="confirm">Confirm</option>
                      </Select>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor={`enable-${index}`} mb="0">
                          Enable
                        </FormLabel>
                        <Switch
                          id={`enable-${index}`}
                          isChecked={desc.enable}
                          onChange={(e) =>
                            handleInputChange(e, index, "enable")
                          }
                        />
                      </FormControl>
                      <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor={`delete-${index}`} mb="0">
                          Delete
                        </FormLabel>
                        <Checkbox
                          id={`delete-${index}`}
                          isChecked={desc.delete}
                          onChange={(e) =>
                            handleInputChange(e, index, "delete")
                          }
                        />
                      </FormControl>
                    </Stack>
                  ))}
                </Stack>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateRingGroup;
