import { useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  useToast,
  Center,
  Image,
} from "@chakra-ui/react";
import axios from "axios";
import API_BASE_URL from "../../config/apiBaseURL";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo1.png";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

function Formulario() {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const captcha = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaToken = await new Promise((resolve) => {
      const token = captcha.current.getValue();
      resolve(token);
    });

    if (!recaptchaToken) {
      toast({
        title: `Please complete the reCAPTCHA challenge.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }

    if (!agreed) {
      toast({
        title: `Please agree to the SMS disclaimer`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }
    if (!terminos) {
      toast({
        title: `Please agree Terms and conditions`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/utils/sendAccessInformation`,
        { customerName, email, mobileNumber }
      );
      if (response) {
        Swal.fire(
          "Thank you for requesting access to our Nevtis VoIP SoftPhone",
          "Your account manager will be emailing you with in 24 business hours with access to our system. If you need immediate access, please contact us at 855.442.7107 or contact your account manager.",
          "success"
        );
        navigate("/app");
      }
    } catch (error) {
      toast({
        title: `An error occurred while submitting the form`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  return (
    <Box maxW="400px" m="auto" p={4}>
      <Center mb={20} mt={5}>
        <Box maxWidth={"200px"}>
          <Image src={logo} />
        </Box>
      </Center>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel color={"nevtis.1"} fontSize={"lg"}>
            Customer Name
          </FormLabel>
          <Input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired mt={4}>
          <FormLabel color={"nevtis.1"} fontSize={"lg"}>
            Email
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired mt={4}>
          <FormLabel color={"nevtis.1"} fontSize={"lg"}>
            Mobile Number
          </FormLabel>
          <Input
            type="tel"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </FormControl>
        <FormControl zIndex={1}>
          <ReCAPTCHA
            sitekey="6Ld1OeQmAAAAANI1clqIrRwdg8K3Y5Q2VzTPtbDv"
            ref={captcha}
          />
        </FormControl>
        <Checkbox
          mt={10}
          isChecked={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
          textAlign={"justify"}
        >
          SMS Disclaimer: Thank you for requesting access to Nevtis VoIP
          Softphone. By checking this option, you agree to receive automated
          Text / SMS / MMS Messages to your mobile number above from Nevtis Corp
          for the purpose of chat communications related to any of our
          platforms. Msg&Data rates may apply. Text Stop to Cancel at any time.
        </Checkbox>
        <Checkbox
          mt={10}
          isChecked={terminos}
          onChange={(e) => setTerminos(e.target.checked)}
          textAlign={"justify"}
        >
          By submitting you agree to our terms and conditions and you understand
          that access to our Nevtis VoIP SoftPhone App may take up to 24
          business hours. If you need immediate access please call us at
          855.442.7107 or contact your account manager
        </Checkbox>
        <Center mt={10}>
          <Button
            size="md"
            w="full"
            type="submit"
            bg={"rgba(255,134,0)"}
            color={"gray.100"}
            _hover={{
              bg: "rgba(255,134,0,0.5)",
            }}
          >
            Send Request
          </Button>
        </Center>
        <Center mt={2}>
          <Button
            zIndex={1}
            onClick={() => navigate("/app")}
            size="md"
            w="full"
            type="submit"
            colorScheme="blue"
            color={"gray.100"}
          >
            Back to Login
          </Button>
        </Center>
      </form>
    </Box>
  );
}

export default Formulario;
