import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/Login";
import ForgotPassword from "../commons/ForgotPassword";
import CreatePassword from "../components/CreatePassword";
import HomeScreenApp from "../components/AppAcrobits/HomeScreenApp";
import FormAccess from "../components/AppAcrobits/FormAccess";
import ForgotPassApp from "../components/AppAcrobits/ForgotPassApp";

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/:token/:userEmail" element={<Login />} />
      <Route path="set-password/:action/:token" element={<CreatePassword />} />
      <Route path="/createPassword" element={<ForgotPassword />} />
      <Route path="/app" element={<HomeScreenApp />} />
      <Route path="/app/access" element={<FormAccess />} />
      <Route path="/app/forgotpass" element={<ForgotPassApp />} />
    </Routes>
  );
};

export default LoginRoutes;
