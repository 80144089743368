import React from "react";
import QRCode from "qrcode.react";
import { Box, Center, Link, Text } from "@chakra-ui/react";

function QrCode({ email, password }) {
  const qrValue = `csc:${email}:${password}@NEVTIS`;

  return (
    <Box>
      <Link href={qrValue} isExternal>
        <Center>
          <QRCode
            size={90}
            value={qrValue} /* fgColor="rgba(255, 134, 0, 1)" */
          />
        </Center>
      </Link>
      <Text
        textAlign={"center"}
        mt={2}
        color={"nevtis.1.8"}
        fontWeight={"bold"}
      >
        Click or Scan QR{" "}
      </Text>
    </Box>
  );
}

export default QrCode;
