import React from "react";
import { Badge, Box, HStack, Tooltip } from "@chakra-ui/react";
import { FaPlus, FaPlay, FaArrowUp, FaCheck, FaTimes } from "react-icons/fa";

const TicketStatus = ({ status }) => {
  let badgeProps = {};

  switch (status) {
    case "New":
      badgeProps = {
        colorScheme: "blue",
        icon: <FaPlus />,
        label: "New",
        tooltip: "The ticket is new and has not been addressed yet.",
      };
      break;
    case "Open":
      badgeProps = {
        colorScheme: "orange",
        icon: <FaPlay />,
        label: "Open",
        tooltip: "The ticket is currently being worked on.",
      };
      break;
    case "Escalated":
      badgeProps = {
        colorScheme: "yellow",
        icon: <FaArrowUp />,
        label: "Escalated",
        tooltip: "The ticket has been escalated to a higher level of support.",
      };
      break;
    case "Resolved":
      badgeProps = {
        colorScheme: "green",
        icon: <FaCheck />,
        label: "Resolved",
        tooltip: "The ticket has been resolved.",
      };
      break;
    case "Unresolved":
      badgeProps = {
        colorScheme: "red",
        icon: <FaTimes />,
        label: "Unresolved",
        tooltip: "The ticket could not be resolved.",
      };
      break;
    default:
      badgeProps = {
        colorScheme: "gray",
        icon: null,
        label: "Unknown",
        tooltip: "The status of the ticket is unknown.",
      };
  }

  return (
    <Tooltip label={badgeProps.tooltip} aria-label={badgeProps.tooltip}>
      <Badge
        colorScheme={badgeProps.colorScheme}
        px={2}
        py={1}
        borderRadius="md"
        cursor="pointer"
      >
        <HStack spacing={1}>
          <Box as="span">{badgeProps.icon}</Box>
          <Box as="span">{badgeProps.label}</Box>
        </HStack>
      </Badge>
    </Tooltip>
  );
};

export default TicketStatus;
