import {
  Box,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Center,
  Heading,
  CircularProgress,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

const TableCalls = ({ user }) => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByExtension/${user.company.domain.domain_uuid}/${user.extension.extension_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching calls:", error);
        setLoading(false);
      });
  }, [user.extension.extension, user.extension.extension_uuid]);

  return (
    <Box mt={10} width={"100%"}>
      {loading ? (
        <Flex justify="center" align="center" h="200px">
          <CircularProgress isIndeterminate color="orange" />
        </Flex>
      ) : calls?.length > 0 ? (
        <TableContainer maxH="700px" overflowY="auto">
          <Heading color={"nevtis.1"} textAlign={"center"} mt={5} mb={10}>
            My Calls
          </Heading>
          <Table variant="simple" size={"sm"}>
            <Thead position="sticky" top={0} zIndex="sticky" bg="white">
              <Tr>
                <Th></Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Ext
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Caller ID Name
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Caller ID Number
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Caller Destination
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Destination Number
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Start Stamp
                </Th>
                <Th textAlign={"center"} color={"nevtis.1"}>
                  Duration
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {calls.map((call, index) => (
                <Tr key={index}>
                  <Td textAlign={"center"}>
                    {call.direction === "inbound" ? (
                      <Icon as={MdArrowDownward} color="blue.500" boxSize={6} />
                    ) : (
                      <Icon
                        as={MdArrowUpward}
                        color="rgba(255,134,0)"
                        boxSize={6}
                      />
                    )}
                  </Td>
                  <Td textAlign={"center"}>
                    {call.ext ? `${call.ext}` : " - "}
                  </Td>
                  <Td textAlign={"center"}>{call.caller_id_name}</Td>
                  <Td textAlign={"center"}>{call.caller_id_number}</Td>
                  <Td textAlign={"center"}>
                    {call.caller_destination
                      ? `${call.caller_destination}`
                      : " - "}
                  </Td>
                  <Td textAlign={"center"}>{call.destination_number}</Td>
                  <Td textAlign={"center"}>
                    {format(
                      new Date(call.start_stamp),
                      "dd MMM yyyy h:mm:ss a"
                    )}
                  </Td>
                  <Td textAlign={"center"}>
                    {call.duration ? `${call.duration}` : "00:00"}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text color={"nevtis.1"} fontSize={"lg"} textAlign={"center"} mt={10}>
          No Calls
        </Text>
      )}
    </Box>
  );
};

export default TableCalls;
