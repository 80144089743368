import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  FormControl,
  Input,
  InputGroup,
  Button,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllActions } from "../../API/actionsAPI";
import { BsPlusCircleFill } from "react-icons/bs";

const TaskChannel = ({ user }) => {
  const { register, handleSubmit, setValue } = useForm();
  const queryTasks = useQuery({
    queryKey: ["tasks", user.email],
    queryFn: ({ queryKey }) => getAllActions(queryKey[1]),
  });

  const onSubmitTask = async (data) => {
    const sendTask = {
      email: user.email,
      tasks: {
        task: data.task,
        done: false,
      },
    };
    const response = await fetch(
      `https://api.nevtis.com/interaction/activities/create`,
      {
        method: "POST",
        body: JSON.stringify(sendTask),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 201) {
    } else {
    }
    setValue("task", "");
    queryTasks.refetch();
  };

  const handleToggleTask = async (task, taskId, done) => {
    const response = await fetch(
      `https://api.nevtis.com/interaction/activities/update/${user.email}/tasks/${taskId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          done: done,
          task: task,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
    } else {
    }
    queryTasks.refetch();
  };

  if (queryTasks.isLoading) {
    return <div>Loading...</div>;
  }

  if (queryTasks.isError) {
    return <div>No data, contact Support</div>;
  }

  return (
    <Box height="96%" width="100%" mt={5}>
      <Box mb="1">
        <form onSubmit={handleSubmit(onSubmitTask)}>
          <FormControl border="solid 3px white" p={2} mt={1} borderRadius="md">
            <InputGroup>
              <Input
                type="text"
                placeholder="Add a Task"
                {...register("task")}
                bg="white"
              />
              <Button type="submit" colorScheme="orange" ml={1}>
                <BsPlusCircleFill />
              </Button>
            </InputGroup>
          </FormControl>
        </form>
      </Box>
      <Box style={{ height: "50vh", maxHeight: "100%", overflow: "auto" }}>
        {queryTasks.data.length === 0 ? (
          <Text color={"nevtis.1"} fontWeight={"bold"} mt={10}>
            No tasks yet
          </Text>
        ) : (
          queryTasks.data[0].tasks.map((task) => (
            <Flex
              key={task._id}
              justify={task.done === true ? "flex-end" : "flex-start"}
              mb={2}
            >
              <Flex
                bg={task.done === true ? "gray.300" : "orange.300"}
                color={task.done === true ? "black" : "white"}
                boxShadow={task.done === true ? "md" : "md"}
                p={2}
                borderRadius="md"
                justifyContent={
                  task.done === true ? "flex-start" : "flex-start"
                }
                flexDirection={task.done === true ? "row-reverse" : "row"}
                width="75%"
              >
                <input
                  type="checkbox"
                  checked={task.done}
                  onChange={(e) =>
                    handleToggleTask(task.task, task._id, e.target.checked)
                  }
                />
                <Text
                  textDecoration={task.done ? "line-through" : "none"}
                  ml={4}
                  mr={4}
                  fontSize="md"
                >
                  {task.task}
                </Text>
              </Flex>
            </Flex>
          ))
        )}
      </Box>
    </Box>
  );
};

export default TaskChannel;
