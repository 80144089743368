import { useState } from "react";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast,
  Box,
  Center,
  Heading,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Settings = () => {
  const toast = useToast();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [name, setName] = useState(user.name);
  const [companyName, setCompanyName] = useState(user.companyName);
  const [phone, setPhone] = useState(user.phone);
  const [address, setAddress] = useState(user.address);
  const [title, setTitle] = useState(user.provisionning?.account.title);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmitPersonalInfo = async (event) => {
    event.preventDefault();
    axios
      .put(`https://api.nevtis.com/user/users/${user.role}/update`, {
        name,
        address,
        phone,
        companyName,
        title,
        _id: user._id,
      })
      .then((res) => {
        if (res.data.name) {
          toast({
            title: `${res.data.name} Updated`,
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          navigate("/");
        } else {
          toast({
            title: `error`,
            status: "error",
            duration: 1500,
            isClosable: true,
          });
        }
      });
  };

  const handleSubmitPassword = (event) => {
    event.preventDefault();
    if (newPassword === confirmPassword) {
      axios
        .put(`https://api.nevtis.com/user/auth/change-password/`, {
          email: user.email,
          oldPassword,
          newPassword,
        })
        .then((res) => {
          if (res.data === "Password changed successfully") {
            toast({
              title: `${user.name}, Password changed successfully`,
              status: "success",
              duration: 1500,
              isClosable: true,
            });
            navigate("/");
          } else {
            toast({
              title: `Error`,
              status: "error",
              duration: 1500,
              isClosable: true,
            });
          }
        });
    } else {
      toast({
        title: `Passwords not match`,
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <Box width={"100%"}>
      <Heading color={"nevtis.1"} textAlign={"center"} my={10}>
        Settings
      </Heading>
      <Tabs variant="soft-rounded" colorScheme={"orange"} mt={10} mr={10}>
        <TabList>
          <Tab>Personal Information</Tab>
          <Tab>Password Change</Tab>
        </TabList>
        <TabPanels mt={2} p={2} boxShadow="2xl" rounded="md">
          <TabPanel>
            <form onSubmit={handleSubmitPersonalInfo}>
              <FormControl id="name" mb={6}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormControl>
              {user.role === "company" ? (
                <FormControl id="companyName" mb={6}>
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    type="text"
                    value={companyName}
                    onChange={(event) => setCompanyName(event.target.value)}
                  />
                </FormControl>
              ) : (
                <FormControl id="title" mb={6}>
                  <FormLabel>Title Nevtis APP</FormLabel>
                  <Input
                    type="text"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                  />
                </FormControl>
              )}

              <FormControl id="phone" mb={6}>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="text"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </FormControl>
              <FormControl id="address" mb={6}>
                <FormLabel>Address</FormLabel>
                <Input
                  type="text"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                />
              </FormControl>
              <Center>
                <Button
                  mt={4}
                  colorScheme="teal"
                  bg={"rgb(255,134,0)"}
                  _hover={{
                    bg: "rgba(255,134,0,0.5)",
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </Center>
            </form>
          </TabPanel>
          <TabPanel>
            <form onSubmit={handleSubmitPassword}>
              <FormControl id="oldPassword" mb={6}>
                <FormLabel>Previous password</FormLabel>
                <Input
                  type="password"
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                />
              </FormControl>
              <FormControl id="newPassword" mb={6}>
                <FormLabel>New password</FormLabel>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <FormHelperText>
                  Must be at least 6 characters long.
                </FormHelperText>
              </FormControl>
              <FormControl id="confirmPassword" mb={6}>
                <FormLabel>Confirm new password</FormLabel>
                <Input
                  type="password"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </FormControl>
              <Center>
                <Button
                  mt={4}
                  colorScheme="teal"
                  bg={"rgb(255,134,0)"}
                  _hover={{
                    bg: "rgba(255,134,0,0.5)",
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </Center>
            </form>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Settings;
