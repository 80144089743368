import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
  Switch,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateVoiceMail = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    voicemailId: "",
    password: "",
    mailTo: "",
    playTutorial: false,
    recordingInstructions: true,
    recordingOptions: true,
    transcriptionEnabled: true,
    keepLocal: true,
    enabled: true,
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submit = async () => {
    if (formData.voicemailId === "" || formData.password === "" || formData.mailTo === "") {
      setError(`Please complete all fields`);
      return;
    }

    const resp = await dispatch();
    if (resp) {
      setFormData({
        voicemailId: "",
        password: "",
        mailTo: "",
        playTutorial: false,
        recordingInstructions: true,
        recordingOptions: true,
        transcriptionEnabled: true,
        keepLocal: false,
        enabled: true,
        description: "",
      });
      onClose();
      showToast("Voicemail created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={10}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            mb={10}
          >
            Create Voicemail
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Voicemail ID</FormLabel>
                  <Input
                    name="voicemailId"
                    value={formData.voicemailId}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Password</FormLabel>
                  <Input
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Mail To</FormLabel>
                  <Input
                    name="mailTo"
                    type="email"
                    value={formData.mailTo}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Play Tutorial
                  </FormLabel>
                  <Switch
                    name="playTutorial"
                    isChecked={formData.playTutorial}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Recording Instructions
                  </FormLabel>
                  <Switch
                    name="recordingInstructions"
                    isChecked={formData.recordingInstructions}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Recording Options
                  </FormLabel>
                  <Switch
                    name="recordingOptions"
                    isChecked={formData.recordingOptions}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Transcription Enabled
                  </FormLabel>
                  <Switch
                    name="transcriptionEnabled"
                    isChecked={formData.transcriptionEnabled}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Keep Local
                  </FormLabel>
                  <Switch
                    name="keepLocal"
                    isChecked={formData.keepLocal}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Enabled
                  </FormLabel>
                  <Switch
                    name="enabled"
                    isChecked={formData.enabled}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateVoiceMail;
