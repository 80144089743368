/* import logger from "redux-logger"; */
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import clientReducer from "./reducers/client.reducer";
import softphoneUser from "./reducers/softphoneUser.reducer";
import { chatReducer, messageReducer } from "./reducers/chat.reducer";
export const store = configureStore({
  /* middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),  */
  reducer: {
    user: userReducer,
    clients: clientReducer,
    softphoneUsers: softphoneUser,
    chats: chatReducer,
    messages: messageReducer,
  },
});
