import React from "react";
import { Flex, Box, Divider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ConversationArea from "./ConversationArea";
import UserChat from "./UserChat";
import ChatNavbar from "./ChatNavbar";

const ChatInterface = () => {
  const chats = [];
  const allMessages = [];

  return (
    <Box
      bg={"gray.100"}
      my={2}
      ml={4}
      mt={20}
      borderRadius={"lg"}
      width={"100%"}
      p={4}
      border={"1px solid rgba(255,134,0,0.2)"}
      boxShadow={"lg"}
    >
      <ChatNavbar />
      <Flex>
        <Box
          w="300px"
          bg="gray.200"
          px={2}
          boxShadow={"xl"}
          borderRadius={"md"}
          overflow={"auto"}
          height={"520"}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
              backgroundColor: "rgba(255, 134, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 134, 0, 0.5)",
              borderRadius: "4px",
            },
          }}
        >
          {chats?.length < 1
            ? null
            : chats.map((chat) => (
                <UserChat
                  chat={chat}
                  key={chat._id}
                  messages={allMessages.filter(
                    (message) => message.chatId === chat._id
                  )}
                />
              ))}
        </Box>

        <Divider orientation="vertical" />

        <ConversationArea />
      </Flex>
    </Box>
  );
};

export default ChatInterface;
