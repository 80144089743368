import React, { useEffect } from "react";
import { Center } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import UserAuthenticatedRoutes from "./routes/UserAuthenticatedRoutes";
import LoginRoutes from "./routes/LoginRoutes";
import Loading from "./commons/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./store/reducers/user.reducer";
import { handleUserRole } from "./utils/usersStatesDispatch";
import axios from "axios";
import API_BASE_URL from "./config/apiBaseURL";
import "./index.css";

function App() {
  const user = useSelector((state) => state.user);

  const loading = null;
  const dispatch = useDispatch();

  useEffect(() => {
    const userLocalStorg = JSON.parse(localStorage.getItem("user"));
    async function fetchUser() {
      try {
        const response = await axios.post(`${API_BASE_URL}/user/auth/me/`, {
          userLocalStorg,
        });
        if (response) {
          dispatch(setUser(response.data));
          handleUserRole(response.data, dispatch);
        }
      } catch (error) {}
    }
    fetchUser();
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Center mt={200}>
          <Loading />
        </Center>
      ) : user.state === "rejected" ? (
        <LoginRoutes />
      ) : (
        <>
          <Navbar />
          <UserAuthenticatedRoutes user={user} />
        </>
      )}
    </>
  );
}

export default App;
