import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Container,
  SimpleGrid,
  Text,
  Icon,
  VStack,
  HStack,
  Badge,
  Button,
  Spinner,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import {
  FaArrowDown,
  FaArrowUp,
  FaClock,
  FaRegPlayCircle,
} from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import { HiStatusOnline } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LiveCalls = ({ customer }) => {
  const [liveCalls, setLiveCalls] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const company = useSelector((state) => state.clients);
  const intervalIdRef = useRef(null);

  const getDomain = (domain) => {
    return company.filter((item) => item.domain.domain_name === domain);
  };

  const fetchLiveCalls = () => {
    axios
      .get("https://api.nevtis.com/fusionpbx/calls/live-calls")
      .then((res) => {
        setLiveCalls(res.data);
      })
      .catch((err) => {
        console.error("Error fetching live calls:", err);
      });
  };

  useEffect(() => {
    if (isFetching && !isPaused) {
      fetchLiveCalls();
      intervalIdRef.current = setInterval(fetchLiveCalls, 1000);
    }

    const pauseFetchAfterTimeout = () => {
      if (isFetching && !isPaused) {
        setIsPaused(true);
      }
    };

    const timeoutId = setTimeout(pauseFetchAfterTimeout, 60000);

    return () => {
      clearInterval(intervalIdRef.current);
      clearTimeout(timeoutId);
    };
  }, [isFetching, isPaused]);

  const handleToggleFetch = () => {
    if (isPaused) {
      setIsPaused(false);
      setIsFetching(true);
    } else {
      setIsPaused(true);
      clearInterval(intervalIdRef.current);
    }
  };

  const calculateCallDuration = (createdEpoch) => {
    const now = Math.floor(Date.now() / 1000);
    const durationInSeconds = now - createdEpoch;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  const extractPBXURL = (call) => {
    if (call.b_presence_id) {
      const parts = call.b_presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.presence_id) {
      const parts = call.presence_id.split("@");
      return parts.length > 1 ? parts[1] : "N/A";
    } else if (call.accountcode) {
      return call.accountcode;
    } else {
      return "";
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const filteredLiveCalls = liveCalls.filter((call) => {
    const domain = extractPBXURL(call);
    return domain === customer?.domain.domain_name;
  });

  const RenderCall = ({ call }) => {
    return (
      <Box>
        {getDomain(extractPBXURL(call))[0]?.companyName !== "N/A" && (
          <Box
            key={call.uuid}
            p={2}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            maxW="sm"
          >
            <HStack mb={2} justify="space-between">
              <Icon
                as={call.direction === "inbound" ? FaArrowDown : FaArrowUp}
                color={call.direction === "inbound" ? "green.500" : "blue.500"}
                boxSize={8}
              />
              <VStack align="start" spacing={0}>
                <Text fontWeight="bold">{call.b_callee_num}</Text>
                <Text fontSize="sm" color="gray.500">
                  {call.cid_num}
                </Text>
              </VStack>
            </HStack>
            <Badge colorScheme="green" mb={2}>
              <Link to={`client/${getDomain(extractPBXURL(call))[0]?._id}`}>
                <Text>
                  {getDomain(extractPBXURL(call))[0]?.companyName || ""}
                </Text>
              </Link>
            </Badge>

            <HStack spacing={1} justify="space-between">
              <HStack spacing={1}>
                <Icon as={HiStatusOnline} color="yellow.500" boxSize={4} />
                <Text fontSize="sm">
                  {capitalizeFirstLetter(call.callstate)}
                </Text>
              </HStack>
              <HStack spacing={1}>
                <Icon as={FaClock} color="orange.500" boxSize={4} />
                <Text fontSize="sm">
                  {calculateCallDuration(call.created_epoch)}
                </Text>
              </HStack>
            </HStack>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Container maxW="100%" mt={4}>
      {isFetching && !isPaused && (
        <Box textAlign="center" mb={4}>
          <Spinner size="xl" />
        </Box>
      )}
      <Flex textAlign="center" mb={4}>
        <IconButton
          bg={"transparent"}
          size={"xs"}
          onClick={handleToggleFetch}
          icon={
            isPaused ? (
              <FaRegPlayCircle color="green" size={"25px"} />
            ) : (
              <FaRegCirclePause color="red" size={"25px"} />
            )
          }
        />
      </Flex>
      {filteredLiveCalls.length === 0 ? (
        <Text color={"nevtis.1"} textAlign={"center"}>
          No Calls
        </Text>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 6 }} spacing={4}>
          {customer
            ? filteredLiveCalls.map((call) => (
                <RenderCall key={call.uuid} call={call} />
              ))
            : liveCalls.map((call) => (
                <RenderCall key={call.uuid} call={call} />
              ))}
        </SimpleGrid>
      )}
    </Container>
  );
};

export default LiveCalls;
