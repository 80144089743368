import React from "react";
import { Route, Routes } from "react-router-dom";
import CreatePassword from "../components/CreatePassword";
import UserActive from "./UserActive";

const UserAuthenticatedRoutes = ({ user }) => {
  return (
    <>
      {user.isActivated === false ? (
        <Routes>
          <Route path="/" element={<CreatePassword />} />
        </Routes>
      ) : (
        <>
          <UserActive user={user} />
        </>
      )}
    </>
  );
};

export default UserAuthenticatedRoutes;
