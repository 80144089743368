import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./store";

const colors = {
  nevtis: {
    1: "rgba(255,134,0)",
    1.1: "rgba(255,134,0,0.1)",
    1.2: "rgba(255,134,0,0.2)",
    1.3: "rgba(255,134,0,0.3)",
    1.4: "rgba(255,134,0,0.4)",
    1.5: "rgba(255,134,0,0.5)",
    1.6: "rgba(255,134,0,0.6)",
    1.7: "rgba(255,134,0,0.7)",
    1.8: "rgba(255,134,0,0.8)",
    1.9: "rgba(255,134,0,0.9)",
  },
};

const theme = extendTheme({ colors });
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
