import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import API_BASE_URL from "../../config/apiBaseURL";

const initialState = [];

export const getAllClient = createAsyncThunk("GET_CLIENTS", () => {
  return axios.get(`${API_BASE_URL}/api/users/client/all`).then((r) => r.data);
});

export const getClientById = createAsyncThunk("GET_CLIENT", (clientId) => {
  return axios
    .get(`${API_BASE_URL}/api/users/client/${clientId}`)
    .then((r) => r.data);
});

export const deleteClient = createAsyncThunk(
  "DELETE_CLIENT",
  async (userId) => {
    const response = await axios.delete(
      `${API_BASE_URL}/api/users/client/delete/${userId}`
    );
    return response.data;
  }
);
export const updateClient = createAsyncThunk("UPDATE_CLIENT", async (data) => {
  const response = await axios.put(
    `${API_BASE_URL}/api/users/client/update`,
    data
  );
  return response.data;
});
export const addVtext = createAsyncThunk("ADD_VTEXT", async (data) => {
  const response = await axios.put(`${API_BASE_URL}/api/vtext-sms/`, data);
  return response.data;
});

export const createClient = createAsyncThunk("CREATE_CLIENT", async (data) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/users/client/create`,
    data
  );
  return response.data;
});

export const setClient = createAction("SET_CLIENT");

const clientReducer = createReducer(initialState, {
  [getAllClient.fulfilled]: (state, action) => action.payload,
  [deleteClient.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.meta.arg
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createClient.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setClient]: (state, action) => action.payload,
  [getClientById.fulfilled]: (state, action) => action.payload,
  [updateClient.fulfilled]: (state, action) => {
    const updateClient = action.payload;
    const index = state.findIndex((client) => client._id === updateClient._id);
    if (index !== -1) {
      state[index] = updateClient;
    }
  },
  [addVtext.fulfilled]: (state, action) => {
    const updateClient = action.payload;
    const index = state.findIndex((client) => client._id === updateClient._id);
    if (index !== -1) {
      state[index] = updateClient;
    }
  },
});

export default clientReducer;
