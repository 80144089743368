import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  IconButton,
  Heading,
  Center,
  Flex,
  CircularProgress,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import { MdRemoveCircle } from "react-icons/md";

const TableQuickdial = ({ user }) => {
  const [softphoneUsers, setAllSoftphoneUsers] = useState([]);
  const [dataQuick, setDataQuick] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetch(`https://api.nevtis.com/user/users/softphoneUser/all`)
        .then((response) => response.json())
        .then((json) => {
          const softphonebyCompany = json.filter(
            (item) => item.company._id === user.company._id && item._id !== user._id
          );
          setAllSoftphoneUsers(softphonebyCompany);
        }),
      fetch(`https://api.nevtis.com/user/provisioning/quickDial/${user._id}`)
        .then((response) => response.json())
        .then((json) => setDataQuick(json)),
    ])
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [user._id, shouldUpdate]);

  async function toggleQuickDial(user_id, action) {
    setLoading(true);
    let url = "";
    if (action === "add") {
      url = `https://api.nevtis.com/user/provisioning/quickDial/add/${user._id}/newQuickdial/${user_id}`;
    } else {
      url = `https://api.nevtis.com/user/provisioning/quickDial/delete/${user._id}/toDelete/${user_id}`;
    }

    try {
      const response = await fetch(url, {
        method: action === "add" ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setShouldUpdate(!shouldUpdate);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box maxHeight={"700px"} overflowY={"auto"} mt={5} ml={20} width={"100%"}>
      {loading ? (
        <Flex justify="center" align="center" h="200px">
          <CircularProgress isIndeterminate color="orange" />
        </Flex>
      ) : softphoneUsers.length > 0 ? (
        <React.Fragment>
          <Heading color={"nevtis.1"} textAlign={"center"} mt={5} mb={10}>
            My QuickDials
          </Heading>
          <Table variant="simple" mt={5} boxShadow="xl" size={"sm"}>
            <Thead position="sticky" top={0} zIndex="sticky" bg="white">
              <Tr>
                <Th color={"rgba(255,134,0)"}>Name</Th>
                <Th color={"rgba(255,134,0)"}>Email</Th>
                <Th color={"rgba(255,134,0)"}>Ext</Th>
                <Th color={"rgba(255,134,0)"}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {softphoneUsers.map((user) => {
                const isQuickDialItem =
                  dataQuick &&
                  dataQuick.find(
                    (item) => item.uri === user.extension.extension.toString()
                  );

                return (
                  <Tr key={user._id}>
                    <Td>
                      <Link to={`/softphone-user/${user._id}`}>
                        <Text color={"nevtis.1"} as={"u"}>
                          {user.name}
                        </Text>
                      </Link>
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>{user.extension.extension}</Td>

                    <Td>
                      {isQuickDialItem ? (
                        <Tooltip
                          hasArrow
                          label="Remove from Quick Dial"
                          bg="red.600"
                        >
                          <IconButton
                            icon={
                              <MdRemoveCircle
                                style={{ fontSize: "20px", color: "red" }}
                              />
                            }
                            size="sm"
                            isDisabled={loading}
                            onClick={() => toggleQuickDial(user._id, "remove")}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          hasArrow
                          label="Add to Quick Dial"
                          bg="green.500"
                        >
                          <IconButton
                            icon={
                              <IoIosAddCircle
                                style={{ fontSize: "20px", color: "green" }}
                              />
                            }
                            size="sm"
                            isDisabled={loading}
                            onClick={() => toggleQuickDial(user._id, "add")}
                          />
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </React.Fragment>
      ) : (
        <Text color={"nevtis.1"} fontSize={"lg"} textAlign={"center"} mt={10}>
          No QuickDials
        </Text>
      )}
    </Box>
  );
};

export default TableQuickdial;
