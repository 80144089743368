import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Select,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateExtension = ({
  customer,
  extensionsByCustomer,
  softphoneUsers,
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  /*   const extensionsNotInSoftphoneUsers = extensionsByCustomer.filter(
    (extension) => {
      return !softphoneUsers.some(
        (user) => user.extension.extension === extension.extension
      );
    }
  ); */

  const [formData, setFormData] = useState({
    extension: "",
    effectiveCallerIdName: "",
    effectiveCallerIdNumber: "",
    outboundCallerIdName: "",
    outboundCallerIdNumber: "",
    emergencyCallerIdName: "",
    emergencyCallerIdNumber: "",
    directoryFullName: "",
    voicemailMailTo: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submit = async () => {
    for (const key in formData) {
      if (formData[key] === "") {
        setError(`Please complete all fields`);
        return;
      }
    }

    let extension = JSON.parse(formData.extension);

    const resp = await dispatch();
    if (resp) {
      setFormData({
        extension: "",
        effectiveCallerIdName: "",
        effectiveCallerIdNumber: "",
        outboundCallerIdName: "",
        outboundCallerIdNumber: "",
        emergencyCallerIdName: "",
        emergencyCallerIdNumber: "",
        directoryFullName: "",
        voicemailMailTo: "",
        description: "",
      });
      onClose();
      showToast("Softphone created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={10}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            mb={10}
          >
            Extension
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>Extension</FormLabel>
                    <Input
                      name="extension"
                      value={formData.effectiveCallerIdName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Effective Caller ID Name
                    </FormLabel>
                    <Input
                      name="effectiveCallerIdName"
                      value={formData.effectiveCallerIdName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Effective Caller ID Number
                    </FormLabel>
                    <Input
                      name="effectiveCallerIdNumber"
                      value={formData.effectiveCallerIdNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Outbound Caller ID Name
                    </FormLabel>
                    <Input
                      name="outboundCallerIdName"
                      value={formData.outboundCallerIdName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Outbound Caller ID Number
                    </FormLabel>
                    <Input
                      name="outboundCallerIdNumber"
                      value={formData.outboundCallerIdNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Emergency Caller ID Name
                    </FormLabel>
                    <Input
                      name="emergencyCallerIdName"
                      value={formData.emergencyCallerIdName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Emergency Caller ID Number
                    </FormLabel>
                    <Input
                      name="emergencyCallerIdNumber"
                      value={formData.emergencyCallerIdNumber}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Directory Full Name
                    </FormLabel>
                    <Input
                      name="directoryFullName"
                      value={formData.directoryFullName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel style={formLabelStyle}>
                      Voicemail Mail To
                    </FormLabel>
                    <Input
                      name="voicemailMailTo"
                      value={formData.voicemailMailTo}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Stack>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateExtension;
