import { useForm } from "react-hook-form";
import { Box, Flex, FormControl, Input, Button, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllActions } from "../../API/actionsAPI";
import { BsTrophy } from "react-icons/bs";
import { URL_INTERACTIONS } from "../../config/apiBaseURL";

const MilestonesChannel = ({ user }) => {
  const { register, handleSubmit, setValue } = useForm();
  const queryMS = useQuery({
    queryKey: ["milestones", user.email],
    queryFn: ({ queryKey }) => getAllActions(queryKey[1]), //queryKey[1] is the user email
  });

  const onSubmitMS = async (data) => {
    const sendMilestone = {
      email: user.email,
      milestones: {
        milestone: data.description,
        milestonedate: data.date,
      },
    };
    const response = await fetch(`https://api.nevtis.com/interaction/activities/create`, {
      method: "POST",
      body: JSON.stringify(sendMilestone),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 201) {
    } else {
    }
    setValue("description", "");
    setValue("date", "");
    queryMS.refetch();
  };

  const deleteMS = async (milestoneId) => {
    const response = await fetch(
      `https://api.nevtis.com/interaction/activities/delete/${user.email}/milestones/${milestoneId}`,
      {
        method: "DELETE",
      }
    );

    if (response.status === 200) {
      queryMS.refetch();
    } else {
    }
  };

  const handleDeleteMilestone = async (noteId) => {
    await deleteMS(noteId);
  };

  if (queryMS.isLoading) {
    return <div>Loading...</div>;
  }

  if (queryMS.isError) {
    return <div>No data, contact Support</div>;
  }

  return (
    <Box height="96%" width="100%" p={4}>
      <Box>
        <form onSubmit={handleSubmit(onSubmitMS)}>
          <FormControl border="solid 3px white" p={2} mt={1} borderRadius="md">
            <Input
              type="text"
              placeholder="Add a Milestone Description"
              {...register("description", { required: true })}
              bg="white"
              flex="1"
              mb="1"
            />
            <Flex alignItems="center">
              <Input
                type="date"
                placeholder="Add a Milestone Date"
                {...register("date", { required: true })}
                bg="white"
                flex="1"
              />
              <Button type="submit" colorScheme="orange" ml={2}>
                <BsTrophy />
              </Button>
            </Flex>
          </FormControl>
        </form>
      </Box>
      <Box style={{ height: "50vh", maxHeight: "100%", overflow: "auto" }}>
        {queryMS.data.length === 0 ? (
          <Text color={"nevtis.1"} fontWeight={"bold"} mt={10}>
            No milestones yet
          </Text>
        ) : (
          queryMS.data[0].milestones.map((milestone) => (
            <Flex
              maxWidth={"95%"}
              key={milestone._id}
              align="center"
              bg="orange.100"
              p={2}
              my={4}
              borderRadius="md"
              position="relative"
            >
              <Box flex="1">
                <Text fontWeight="bold">{milestone.milestone}</Text>
                <Text>
                  {new Date(milestone.milestonedate).toLocaleString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour12: false,
                  })}
                </Text>
              </Box>
              <Button
                colorScheme="orange"
                size="sm"
                onClick={() => handleDeleteMilestone(milestone._id)}
                position="absolute"
                top="-12px"
                right="-12px"
                zIndex="1"
              >
                X
              </Button>
              <Box
                position="absolute"
                bottom="-12px"
                left="0"
                borderLeft="12px solid transparent"
                borderBottom="12px solid orange.100"
                zIndex="0"
              />
            </Flex>
          ))
        )}
      </Box>
    </Box>
  );
};

export default MilestonesChannel;
