import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeCustomer from "../components/Customer/HomeCustomer";
import TableSoftphoneUsers from "../components/Customer/Gest Softphone/TableSoftphoneUsers";
import SoftphoneDetailsTabs from "../components/Customer/Gest Softphone/SoftphoneDetailsTabs";
import { useSelector } from "react-redux";
import TabCalls from "../components/Customer/callsDetails/TabCalls";
import NevtisPbx from "../components/Customer/fusionpbx/NevtisPbx";
import TicketsTabs from "../components/Customer/ticket/TicketsTabs";
import InConstruction from "../components/InConstruction";

const ClientRoutes = () => {
  const user = useSelector((state) => state.user);
  return (
    <Routes>
      <Route path="/" element={<HomeCustomer user={user} />} />
      <Route
        path="/softphoneUsers"
        element={<TableSoftphoneUsers customer={user} />}
      />
      <Route
        path="/softphone-user/:softphoneId"
        element={<SoftphoneDetailsTabs />}
      />
      <Route path="/calls" element={<TabCalls customer={user} />} />
      <Route path="/tickets" element={<InConstruction title={"Tickets"} />} />
      <Route path="/nevtisPbx" element={<NevtisPbx customer={user} />} />
    </Routes>
  );
};

export default ClientRoutes;
