import { Box, Text, CircularProgress, Flex, Icon } from "@chakra-ui/react";
import { MdPhone, MdCallReceived, MdCallMade } from "react-icons/md";
import axios from "axios";
import React, { useEffect, useState } from "react";

const DashboardCalls = ({ user }) => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByExtension/${user.company.domain.domain_uuid}/${user.extension.extension_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching calls:", error);
        setLoading(false);
      });
  }, [user.extension.extension, user.extension.extension_uuid]);

  const today = new Date().toISOString().split("T")[0];
  const callsToday = calls.filter((call) => call.start_stamp.includes(today));

  const countInbound = callsToday.filter(
    (call) => call.direction === "inbound"
  ).length;
  const countOutbound = callsToday.filter(
    (call) => call.direction === "outbound"
  ).length;

  return (
    <Box p="4">
      {loading ? (
        <Flex justify="center" align="center" h="200px">
          <CircularProgress isIndeterminate color="blue.300" />
        </Flex>
      ) : (
        <Box py={6}>
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="gray.600"
            textAlign="center"
            mb={20}
          >
            Calls Today
          </Text>
          <Flex align="center" mb={4}>
            <Icon as={MdPhone} boxSize="2em" color="gray.400" mr={3} />
            <Text fontSize={"lg"}>Total Calls: {callsToday.length}</Text>
          </Flex>
          <Flex align="center" mb={4}>
            <Icon as={MdCallReceived} boxSize="2em" color="green.500" mr={3} />
            <Text fontSize={"lg"}>Inbound Calls: {countInbound}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={MdCallMade} boxSize="2em" color="red.500" mr={3} />
            <Text fontSize={"lg"}>Outbound Calls: {countOutbound}</Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default DashboardCalls;
