import React from "react";
import { useForm } from "react-hook-form";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useMediaQuery } from "@chakra-ui/react";
import {
  Flex,
  Box,
  Image,
  Heading,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Center,
  Link,
} from "@chakra-ui/react";
/* import ReCAPTCHA from "react-google-recaptcha"; */
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import AlertMessage from "../commons/AlertMessage";
import { loginRequest } from "../store/reducers/user.reducer";
import { handleUserRole } from "../utils/usersStatesDispatch";
import office from "../assets/office2.jpg";
import logo from "../assets/logo1.png";

export default function Login() {
  const master = useParams().token;
  const client = useParams().userEmail;

  const [isLargerThan600] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  /*   const captcha = useRef(null); */

  const onSubmit = async (data) => {
    const { email, password } = data;
    if (email && password) {
      /*       const recaptchaToken = await new Promise((resolve) => {
        const token = captcha.current.getValue();
        resolve(token);
      });

      if (!recaptchaToken) {
        Toast.fire({
          icon: "error",
          title: "Please complete the reCAPTCHA challenge.",
        });
        return;
      } */

      const resp = await dispatch(loginRequest(data));
      const user = resp.payload.user;
      if (!user) {
        Toast.fire({
          icon: "error",
          title: `${resp.payload}`,
        });
      }
      handleUserRole(user, dispatch);

      navigate("/");
    }
  };

  return (
    <Flex mt={2} direction={isLargerThan600 ? "row" : "column"}>
      {!isLargerThan600 && (
        <Center>
          <Image src={logo} alt="devices" w="40" overflowY="none" />
        </Center>
      )}

      <Box
        bg="whiteAlpha.500"
        borderRadius="lg"
        w={isLargerThan600 ? "30%" : "100%"}
        mt={20}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box m={6} color="#0B0E3F">
          <Center>
            <Heading
              as="h1"
              fontSize="2xl"
              fontWeight="bold"
              color="orange.300"
              mb="10"
            >
              Login
            </Heading>
          </Center>
          <VStack spacing={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="email" isRequired>
                <FormLabel color={"rgba(255,134,0)"}>Email address</FormLabel>
                <Input type="email" {...register("email")} value={client} />
              </FormControl>
              <FormControl id="password" isRequired py={7}>
                <FormLabel color={"rgba(255,134,0)"}>Password</FormLabel>
                <Input
                  type="password"
                  {...register("password")}
                  value={master}
                />
              </FormControl>
              {/*               <FormControl>
                <ReCAPTCHA
                  sitekey="6Ld1OeQmAAAAANI1clqIrRwdg8K3Y5Q2VzTPtbDv"
                  ref={captcha}
                />
              </FormControl> */}

              <AlertMessage />
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              ></Stack>
              <Center>
                <Button
                  size="sm"
                  w="full"
                  type="submit"
                  bg={"rgba(255,134,0)"}
                  color={"gray.100"}
                  _hover={{
                    bg: "rgba(255,134,0,0.5)",
                  }}
                >
                  Login
                </Button>
              </Center>
              <ReactRouterLink to={"/createPassword"}>
                <small>Forgot Password?</small>
              </ReactRouterLink>
            </form>
          </VStack>
        </Box>
        <Box bg="gray.50" mt={!isLargerThan600 && 60}>
          <Center>
            <Heading color={"rgba(255,134,0)"} size="md">
              Contact us:
            </Heading>
          </Center>
          <Center>
            <Button
              size="xs"
              height="40px"
              width="200px"
              variant="ghost"
              color="gray.600"
              _hover={{ border: "2px solid #0987a0" }}
              leftIcon={<MdPhone color="rgba(255,134,0)" size="20px" />}
            >
              (855) 442-7107
            </Button>
            <Button
              size="xs"
              height="40px"
              width="200px"
              variant="ghost"
              color="gray.600"
              _hover={{ border: "2px solid #0987a0" }}
              leftIcon={<MdLocationOn color="rgba(255,134,0)" size="20px" />}
            >
              Blvd Suite 101, Anaheim
            </Button>
          </Center>
          <Center>
            <Link href="https://nevtis.com/contact-us" isExternal>
              <Button
                size="sm"
                height="40px"
                width="200px"
                variant="ghost"
                color="gray.600"
                _hover={{ border: "2px solid #0987a0" }}
                leftIcon={<TbWorld color="rgba(255,134,0)" size="20px" />}
              >
                www.nevtis.com
              </Button>
            </Link>
            <Button
              size="xs"
              height="40px"
              width="200px"
              variant="ghost"
              color="gray.600"
              _hover={{ border: "2px solid #0987a0" }}
              leftIcon={<MdEmail color="rgba(255,134,0)" size="20px" />}
            >
              hello@nevtis.com
            </Button>
          </Center>
        </Box>
      </Box>
      {isLargerThan600 && (
        <Box w="70%">
          <Image src={office} alt="devices" w="full" overflowY="none" />
        </Box>
      )}
    </Flex>
  );
}
