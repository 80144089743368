import React, { useEffect, useState } from "react";
import Loading from "../../../commons/Loading";
import {
  Box,
  Grid,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import TableQuickDial from "./TableQuickdial";
import TableCustomerCalls from "../callsDetails/TableCustomerCalls";
import axios from "axios";

const SoftphoneDetail = ({ user }) => {
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByExtension/${user.company.domain.domain_uuid}/${user.extension.extension_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      });
  }, [
    user.company.domain.domain_uuid,
    user.extension.extension,
    user.extension.extension_uuid,
  ]);

  if (!user) {
    return <Loading />;
  }
  return (
    <Box>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        mb="4"
        textAlign={"center"}
      >
        <Text fontWeight={"bold"}>Name: {user.name}</Text>
        <Text fontWeight={"bold"}>Email: {user.email}</Text>
        <Text fontWeight={"bold"}>Phone: {user.phone}</Text>
        <Text fontWeight={"bold"}>Address: {user.address}</Text>
        <Text fontWeight={"bold"}>
          Nº Extension: {user.extension.extension}
        </Text>
        <Text fontWeight={"bold"}>
          Outbound Caller Name:{" "}
          {user.extension.outbound_caller_id_name
            ? user.extension.outbound_caller_id_name
            : " - "}
        </Text>
        <Text fontWeight={"bold"}>
          Effective CID Name:{" "}
          {user.extension.effective_caller_id_name
            ? user.extension.effective_caller_id_name
            : " - "}
        </Text>
        <Text fontWeight={"bold"}>
          Company:
          <Text color={"nevtis.1"} as={"u"} ml={2}>
            {user.company.companyName}
          </Text>
        </Text>
      </Grid>
      <Tabs variant="soft-rounded" colorScheme="orange" mt={10}>
        <TabList>
          <Tab>Calls</Tab>
          <Tab>Quick Dial</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableCustomerCalls calls={calls} />
          </TabPanel>
          <TabPanel>
            <TableQuickDial user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SoftphoneDetail;
