import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";
import CreateDestination from "./CreateDestination";
const TableDestinationsByCustomer = ({
  destinations,
  extensionesAsignadas,
  ringGroup,
  faxServers,
  ivrMenus,
  voicemail,
}) => {
  function extractNumber(destinationData) {
    // Buscar el patrón *99 seguido de uno o más dígitos, o simplemente un número de uno o más dígitos
    var resultado = destinationData.match(/\*99(\d+)|(\d+)/);

    // Verificar si se encontró el patrón
    if (resultado !== null) {
      if (resultado[1]) {
        return `voicemail - ${resultado[1]}`; // Si coincide con el patrón *99 seguido de dígitos, devolver "voicemail"
      } else {
        var numeroDespuesDeAsterisco = resultado[2]; // Obtener el número después del asterisco o simplemente el número
        // Clasificar los números según los rangos especificados
        if (numeroDespuesDeAsterisco >= 100 && numeroDespuesDeAsterisco < 200) {
          return `extension - ${numeroDespuesDeAsterisco}`;
        } else if (
          numeroDespuesDeAsterisco >= 1000 &&
          numeroDespuesDeAsterisco < 2000
        ) {
          return `ringgroup - ${numeroDespuesDeAsterisco}`;
        } else if (
          numeroDespuesDeAsterisco >= 4000 &&
          numeroDespuesDeAsterisco < 5000
        ) {
          return `IVR Menus - ${numeroDespuesDeAsterisco}`;
        } else {
          return numeroDespuesDeAsterisco; // Devolver el número original si no está en los rangos especificados
        }
      }
    } else {
      return "-";
    }
  }

  return (
    <Box>
      <CreateDestination
        extensions={extensionesAsignadas}
        ringGroup={ringGroup}
        faxServers={faxServers}
        ivrMenus={ivrMenus}
        voicemail={voicemail}
      />
      <TableContainer mt={10} maxH="450px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Number
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                CID Name
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {destinations &&
              destinations.map((dest) => (
                <Tr key={dest.destination_uuid}>
                  <Td textAlign={"center"}>{dest.destination_number}</Td>
                  <Td textAlign={"center"}>
                    {dest.destination_caller_id_name}
                  </Td>
                  <Td textAlign={"center"}>{dest.destination_description}</Td>
                  <Td textAlign={"center"}>
                    {dest.destination_actions &&
                      dest.destination_actions.length > 0 &&
                      extractNumber(
                        dest.destination_actions[0].destination_data
                      )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableDestinationsByCustomer;
