import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import API_BASE_URL from "../../config/apiBaseURL";

const initialState = {
  state: "rejected",
};

export const loginRequest = createAsyncThunk("USER_LOGIN", async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/auth/login/`, {
      email: userData.email,
      password: userData.password,
    });

    const { user, token } = response.data;

    if (
      (user.role === "softphoneUser" || user.role === "company") &&
      user.isActivated
    ) {
      localStorage.setItem("user", JSON.stringify({ token }));
      return response.data;
    } else {
      throw new Error("Unauthorized role");
    }
  } catch (error) {
    return error.response?.data?.message || "An error occurred during login";
  }
});

export const logoutRequest = createAsyncThunk("USER_LOGOUT", () => {
  localStorage.clear();
  return { state: "rejected" };
});

export const createPassRequest = createAsyncThunk("CREATE_PASS", (userData) => {
  axios
    .post(`${API_BASE_URL}/user/auth/newpass`, {
      email: userData.email,
      token: userData.token,
      password: userData.password,
    })
    .then((r) => r.data);
  return { state: "rejected" };
});

export const forgotPassRequest = createAsyncThunk("FORGOT_PASS", (userData) => {
  return axios
    .put(`${API_BASE_URL}/user/auth/forgot-password`, {
      email: userData,
    })
    .then((r) => r.data);
});

export const resetPassRequest = createAsyncThunk("RESET_PASS", (userData) => {
  const { resetLink, newPassword } = userData;
  return axios
    .put(`${API_BASE_URL}/user/auth/reset-password`, {
      resetLink,
      newPassword,
    })
    .then((r) => r.data);
});

export const setUser = createAction("SET_USER");

export const getUserRequest = createAsyncThunk("GET_USER", (userId) => {
  return axios.get(`/api/users/${userId}`).then((r) => r.data);
});

const userReducer = createReducer(initialState, {
  [loginRequest.fulfilled]: (state, action) => action.payload.user,
  [createPassRequest.fulfilled]: (state, action) => action.payload,
  [forgotPassRequest.fullfilled]: (state, action) => action.payload,
  [resetPassRequest.fullfilled]: (state, action) => action.payload,
  [setUser]: (state, action) => action.payload,
  [logoutRequest.fulfilled]: (state, action) => action.payload,
  [getUserRequest.fulfilled]: (state, action) => action.payload,
});

export default userReducer;
