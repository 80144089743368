import {
  createAsyncThunk,
  createReducer,
  createAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { useState, useEffect } from "react";
import { URL_COMUNICATION, URL_USER } from "../../config/apiBaseURL";

const initialChatState = {
  chats: [],
  onlineUsers: [],
  currentChat: null,
  notifications: [],
};
const initialMessageState = [];

export const updateOnlineUsers = createAction("UPDATE_ONLINE_USERS");
export const updateNotifications = createAction("UPDATE_NOTIFICATIONS");
export const updateCurrentChat = createAction("UPDATE_CURRENT_CHAT");
export const usePotentialChats = (user, chats) => {
  const [potentialChats, setPotentialChats] = useState([]);
  useEffect(() => {
    const fetchPotentialChats = async () => {
      try {
        const response = await axios.get(`https://api.nevtis.com/user/users/allUsers`);
        const users = response.data;
        const filteredUsers = users.filter((potentialUser) => {
          return (
            !chats.some((chat) => chat.members.includes(potentialUser._id)) &&
            potentialUser._id !== user._id
          );
        });
        setPotentialChats(filteredUsers);
      } catch (error) {
        console.error("Error fetching potential chats:", error);
      }
    };

    fetchPotentialChats();
  }, [chats, user._id]);

  return potentialChats;
};
export const createChat = createAsyncThunk(
  "CREATE_CHAT",
  async ({ firstId, secondId }) => {
    const response = await axios.post(`https://api.nevtis.com/comunication/chat/create`, {
      firstId,
      secondId,
    });
    return response.data;
  }
);
export const findUserChats = createAsyncThunk(
  "FIND_USER_CHATS",
  async (userId) => {
    const response = await axios.get(`https://api.nevtis.com/comunication/chat/${userId}`);
    return response.data;
  }
);
export const findChat = createAsyncThunk(
  "FIND_CHAT",
  async ({ firstId, secondId }) => {
    const response = await axios.get(
      `https://api.nevtis.com/comunication/chat/find/${firstId}/${secondId}`
    );
    return response.data;
  }
);

const chatReducer = createReducer(initialChatState, {
  [createChat.fulfilled]: (state, action) => {
    state.chats.push(action.payload);
  },
  [findUserChats.fulfilled]: (state, action) => {
    state.chats = action.payload;
  },
  [findChat.fulfilled]: (state, action) => {
    state.chats = action.payload;
  },
  [updateOnlineUsers]: (state, action) => {
    state.onlineUsers = action.payload;
  },
  [updateCurrentChat]: (state, action) => {
    state.currentChat = action.payload;
    state.notifications = state.notifications.filter(
      (notif) => !action.payload?.members?.includes(notif.senderId)
    );
  },
  [updateNotifications]: (state, action) => {
    const notificationsToAdd = Array.isArray(action.payload)
      ? action.payload
      : [action.payload];
    state.notifications = [...state.notifications, ...notificationsToAdd];
  },
});

export const createMessage = createAsyncThunk(
  "CREATE_MESSAGE",
  async (messageData) => {
    const response = await axios.post(
      `https://api.nevtis.com/comunication/messages/create`,
      messageData
    );
    return response.data;
  }
);
export const getMessagesByChatId = createAsyncThunk(
  "GET_MESSAGES_BY_CHAT_ID",
  async (chatId) => {
    if (!chatId) return;
    const response = await axios.get(
      `https://api.nevtis.com/comunication/messages/${chatId}`
    );
    return response.data;
  }
);
export const addNewMessage = (message) => ({
  type: "ADD_NEW_MESSAGE",
  payload: message,
});

const messageReducer = createReducer(initialMessageState, {
  [createMessage.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [getMessagesByChatId.fulfilled]: (_, action) => {
    return action.payload;
  },
  ADD_NEW_MESSAGE: (state, action) => {
    state.push(action.payload);
  },
});

export { chatReducer, messageReducer };
