import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Stack,
  TableContainer,
  Center,
  Heading,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CreateSoftphoneUser from "./CreateSoftphoneUser";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../commons/Pagination";
import axios from "axios";

const TableSoftphoneUsers = ({ customer }) => {
  const allSoftphoneUsers = useSelector((state) => state.softphoneUsers);
  const [allExtensions, setAllExtension] = useState([]);
  const user = useSelector((state) => state.user);
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [ItemsPerPage, setItemsPerPage] = useState(100);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/extensions/getAllExtensions/resume`
      )
      .then((res) => {
        setAllExtension(res.data);
      });
  }, []);

  const extensionsByCustomer =
    customer && customer.domain
      ? allExtensions.filter(
          (item) => item.domain_uuid === customer.domain.domain_uuid
        )
      : [];

  const softphoneUsers = allSoftphoneUsers.filter(
    (item) => item.company._id === customer._id
  );

  const filteredDataSw = softphoneUsers;
  const filteredData = filteredDataSw.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const handleInputPerPage = (value) => {
    setItemsPerPage(Number(value));
  };

  const sections = filteredData.reduce(
    (acc, item, index) =>
      index % ItemsPerPage === 0
        ? [...acc, filteredData.slice(index, index + ItemsPerPage)]
        : acc,
    []
  );

  const currentSection = sections[currentPage - 1];

  const handleSearchChange = (e) => setSearchValue(e.target.value);

  return (
    <Box mt={20} px={10} width={"100%"}>
      <Heading color={"nevtis.1"} textAlign={"center"} mb={10}>
        Softphone Users
      </Heading>
      <Stack direction={{ base: "column", md: "row" }} spacing={10} mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by email"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Flex justifyContent="flex-end" alignItems="center">
          <Text color={"nevtis.1"} mr={2}>
            Items per Page
          </Text>
          <NumberInput
            size="xs"
            maxW={16}
            defaultValue={ItemsPerPage}
            min={1}
            onChange={handleInputPerPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Stack>

      <CreateSoftphoneUser
        customer={customer}
        extensionsByCustomer={extensionsByCustomer}
        softphoneUsers={softphoneUsers}
      />
      <TableContainer mt={2} maxHeight={"600px"} overflowY="scroll">
        <Table variant="simple" size={isDesktop ? "2xl" : "xs"}>
          <Thead
            position={"sticky"}
            zIndex={1}
            top={0}
            bg={"white"}
            fontSize={!isDesktop && "xs"}
            textAlign={!isDesktop && "center"}
          >
            <Tr>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Name
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Email
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                Phone
              </Th>
              <Th
                color={"rgba(255,134,0)"}
                height="50px"
                textAlign={"center"}
                fontSize={"sm"}
              >
                <p>Extension</p>
              </Th>
              {(softphoneUsers.length === 0 || !softphoneUsers[0].leads) && (
                <Th
                  color={"rgba(255,134,0)"}
                  height="50px"
                  textAlign={"center"}
                  fontSize={"sm"}
                >
                  Status
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {currentSection &&
              currentSection
                .filter((userP) => userP._id !== user._id)
                .map((item) => (
                  <Tr key={item._id}>
                    {isDesktop ? (
                      <>
                        <Td textAlign={"center"}>
                          {item.name && (
                            <Link to={`/softphone-user/${item._id}`}>
                              <Text color={"nevtis.1"} as={"u"}>
                                {item.name.match(/\b(\w+)\b/g)[0]}
                              </Text>
                            </Link>
                          )}
                        </Td>

                        <Td textAlign={"center"}>
                          {item.email?.length > 20
                            ? item.email?.slice(0, 20) + "..."
                            : item.email}
                        </Td>

                        <Td textAlign={"center"}>{item.phone}</Td>

                        <Td textAlign={"center"}>{item.extension.extension}</Td>

                        <Td>
                          {item.isActivated ? (
                            <Center>
                              <Badge
                                backgroundColor={"rgba(255,134,0)"}
                                color="gray.50"
                                p={1}
                                borderRadius={5}
                              >
                                Active
                              </Badge>
                            </Center>
                          ) : (
                            <Center>
                              <Badge
                                backgroundColor={"gray.300"}
                                color={"rgba(255,134,0,0.5)"}
                              >
                                Inactive
                              </Badge>
                            </Center>
                          )}
                        </Td>
                      </>
                    ) : (
                      <>
                        <Td textAlign={!isDesktop && "center"}>
                          {item.name && (
                            <Link to={`/softphone-user/${item._id}`}>
                              <Text color={"nevtis.1"} as={"u"}>
                                {item.name.match(/\b(\w+)\b/g)[0]}
                              </Text>
                            </Link>
                          )}
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        sections={sections}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};

export default TableSoftphoneUsers;
