import React, { useEffect, useState } from "react";
import Loading from "../../../commons/Loading";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
  Heading,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import TableExtensions from "./TableExtensions";
import TableDestinationsByCustomer from "./TableDestinationsByCustomer";
import TableRingGroups from "./TableRingGroups";
import axios from "axios";
import TableFaxServer from "./TableFaxServer";
import TableIVR from "./TableIVR";
import TableVoiceMail from "./TableVoiceMail";
import TableTickets from "./TableTickets";

const NevtisPbx = ({ customer }) => {
  const allSoftphoneUsers = useSelector((state) => state.softphoneUsers);
  const [destinations, setDestinations] = useState([]);
  const [ringGroup, setRingGroup] = useState([]);
  const [allExtensions, setAllExtension] = useState([]);
  const [faxServers, setFaxServers] = useState([]);
  const [ivrMenus, setIvrMenus] = useState([]);
  const [voicemail, setVoicemail] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/extensions/getAllExtensions/resume`
      )
      .then((res) => {
        setAllExtension(res.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/destinations/getAllDestinationsByDomain/${customer.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setDestinations(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/ring-groups/getAllRingGroupsByDomain/${customer.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setRingGroup(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/fax-servers/getAllFaxServersByDomain/${customer.domain.domain_uuid}/all`
      )
      .then((resp) => {
        setFaxServers(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/ivr-menus/getAllIvrMenusByDomain/${customer.domain.domain_uuid}`
      )
      .then((resp) => {
        setIvrMenus(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);
  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/voicemails/getAllVoicemailByDomain/${customer.domain.domain_uuid}`
      )
      .then((resp) => {
        setVoicemail(resp.data);
      })
      .catch((error) => {
        setError("No data, please contact support");
      });
  }, [customer.domain.domain_uuid]);

  const extensionsByCustomer =
    customer && customer.domain
      ? allExtensions.filter(
          (item) => item.domain_uuid === customer.domain.domain_uuid
        )
      : [];

  const extensionesAsignadas = extensionsByCustomer.map((extension) => {
    const softphone = allSoftphoneUsers.find(
      (user) => user.extension.extension_uuid === extension.extension_uuid
    );
    return {
      extension_number: extension.extension,
      effective_caller_id_name: extension.effective_caller_id_name,
      outbound_caller_id_number: extension.outbound_caller_id_number,
      outbound_caller_id_name: extension.outbound_caller_id_name,
      softphone_user: softphone ? softphone : null,
    };
  });

  if (!customer) {
    return <Loading />;
  }

  if (error) {
    return (
      <Text textAlign={"center"} color={"nevtis.1"} fontWeight={"bold"} mt={5}>
        {error}
      </Text>
    );
  }

  return (
    <Box width={"100%"} mt={20}>
      <Heading color={"nevtis.1"} textAlign={"center"} mb={10}>
        Nevtis PBX
      </Heading>
      <Tabs variant="soft-rounded" colorScheme="orange" mt={4}>
        <TabList>
          <Tab>Extensions ({extensionesAsignadas.length})</Tab>
          <Tab>Destinations ({destinations.length})</Tab>
          <Tab>Ring Groups ({ringGroup.length})</Tab>
          <Tab>Fax Servers ({faxServers.length})</Tab>
          <Tab>IVR Menus ({ivrMenus.length})</Tab>
          <Tab>Voicemails ({voicemail.length})</Tab>
          <Tab>Tickets ({ringGroup.length})</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableExtensions extensionesAsignadas={extensionesAsignadas} />
          </TabPanel>
          <TabPanel>
            <TableDestinationsByCustomer
              extensionesAsignadas={extensionesAsignadas}
              destinations={destinations}
              ringGroup={ringGroup}
              faxServers={faxServers}
              ivrMenus={ivrMenus}
              voicemail={voicemail}
            />
          </TabPanel>
          <TabPanel>
            <TableRingGroups ringGroup={ringGroup} />
          </TabPanel>
          <TabPanel>
            <TableFaxServer data={faxServers} />
          </TabPanel>
          <TabPanel>
            <TableIVR
              data={ivrMenus}
              extensionesAsignadas={extensionesAsignadas}
              ringGroup={ringGroup}
              faxServers={faxServers}
              voicemail={voicemail}
            />
          </TabPanel>
          <TabPanel>
            <TableVoiceMail data={voicemail} />
          </TabPanel>
          <TabPanel>
            <TableTickets data={ringGroup} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NevtisPbx;
