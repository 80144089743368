import { Box, Center, Flex, Icon, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import QrCode from "../../commons/GenerateQr";
import axios from "axios";
import CardSoftphoneUsers from "../../commons/CardSoftphoneUsers";
import DashboardInfoBox from "./DashboardInfoBox";
import DashboardCalls from "./DashboardCalls";
import { Link } from "react-router-dom";

const HomeSoftPhone = ({ user }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [allSoftphoneUsers, setAllSoftphoneUsers] = useState([]);
  const [calls, setCalls] = useState([]);
  const [dataQuick, setDataQuick] = useState(null);
  const softphoneUsers = allSoftphoneUsers.filter(
    (item) => item.company._id === user.company
  );

  useEffect(() => {
    fetch(`https://api.nevtis.com/user/users/softphoneUser/all`)
      .then((response) => response.json())
      .then((json) => {
        setAllSoftphoneUsers(json);
      })
      .catch((error) => console.error(error));
  }, [user._id]);
  useEffect(() => {
    fetch(`https://api.nevtis.com/user/users/user/${user._id}`)
      .then((response) => response.json())
      .then((json) => {
        setDataQuick(json);
      })
      .catch((error) => console.error(error));
  }, [user._id]);

  useEffect(() => {
    axios
      .get(
        `https://api.nevtis.com/fusionpbx/calls/getCallsByExtension/${user.company.domain.domain_uuid}/${user.extension.extension_uuid}`
      )
      .then((resp) => {
        setCalls(resp.data);
      });
  }, [user.extension.extension, user.extension.extension_uuid]);

  const filteredTrue = softphoneUsers.filter(
    (item) =>
      item._id !== user._id &&
      dataQuick &&
      dataQuick.quickDial &&
      dataQuick.quickDial.item &&
      dataQuick.quickDial.item.find(
        (dial) => dial.uri === item.extension.extension.toString()
      )
  );

  const filteredFalse = softphoneUsers.filter(
    (item) =>
      item._id !== user._id &&
      dataQuick &&
      dataQuick.quickDial &&
      dataQuick.quickDial.item &&
      !dataQuick.quickDial.item.find(
        (dial) => dial.uri === item.extension.extension.toString()
      )
  );

  return (
    <Box width={"100%"}>
      <Box
        borderRadius="sm"
        mt={isDesktop ? 10 : 10}
        p={isDesktop && 4}
        ml={20}
      >
        <Flex flexDirection={isDesktop ? "row" : "column"}>
          <Box maxWidth={"500px"}>
            <Text
              fontSize={isDesktop ? "5xl" : "3xl"}
              fontWeight={"bold"}
              fontFamily="mono"
              color="nevtis.1"
              textAlign={!isDesktop && "center"}
            >
              Hi, {user.name.split(" ").slice(0, 2).join(" ")}
            </Text>
            <Text
              px={2}
              textAlign={!isDesktop && "center"}
              fontSize={isDesktop ? "xl" : "md"}
              fontFamily="mono"
              color="gray.600"
            >
              It's great to see you again. I hope you're having a fantastic day.
            </Text>
          </Box>
          <Center pt={isDesktop ? 5 : 4}>
            <QrCode
              email={user.provisionning.account.cloud_username}
              password={user.provisionning.account.cloud_password}
            />
          </Center>
        </Flex>
      </Box>

      <Center>
        <Flex
          direction={{ base: "column", md: "row" }}
          mt={isDesktop ? "80px" : "60px"}
          justify={{ base: "center", md: "center" }}
          align={{ base: "center", md: "center" }}
        >
          <Box
            boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
            borderRadius="xl"
            px={10}
            py={6}
          >
            <DashboardInfoBox user={user} />
          </Box>

          <Box mx={5} />
          <Link to={"/quickdial"}>
            <Box
              boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
              borderRadius="xl"
              px={10}
              py={20}
            >
              <Box />
              <CardSoftphoneUsers path="QuickDial" avatar={filteredTrue} />
              <Box my={2} />
              <CardSoftphoneUsers path="Remaining" avatar={filteredFalse} />
            </Box>
          </Link>

          <Box mx={5} />
          <Link to={"/calls"}>
            <Box
              boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
              borderRadius="xl"
              px={10}
              py={7}
            >
              <DashboardCalls user={user} />
            </Box>
          </Link>
        </Flex>
      </Center>
    </Box>
  );
};

export default HomeSoftPhone;
