import { getAllSoftphoneUser } from "../store/reducers/softphoneUser.reducer";

export function handleUserRole(user, dispatch) {
  switch (user?.role) {
    case "company":
      dispatch(getAllSoftphoneUser(user._id));
      break;
    case "softphoneUser":
      dispatch(getAllSoftphoneUser(user.company._id));
      break;
    default:
      break;
  }
}
