import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Stack,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Input,
  FormControl,
  Button,
  Box,
  useDisclosure,
  Text,
  Switch,
  Select,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { showToast } from "../../../commons/SweetAlertToast";

const CreateIVR = ({
  extensionesAsignadas,
  ringGroup,
  faxServers,
  voicemail,
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    extension: "",
    timeout: "",
    exitAction: "",
    enabled: true,
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submit = async () => {
    if (
      formData.name === "" ||
      formData.extension === "" ||
      formData.timeout === "" ||
      formData.exitAction === ""
    ) {
      setError(`Please complete all fields`);
      return;
    }

    const resp = await dispatch();
    if (resp) {
      setFormData({
        name: "",
        extension: "",
        timeout: "",
        exitAction: "",
        enabled: false,
        description: "",
      });
      onClose();
      showToast("IVR created", "success");
    } else {
      showToast("Error", "error");
    }
  };

  return (
    <>
      <Center mt={1}>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          bg="rgb(255,134,0)"
          _hover={{ bg: "rgba(255,134,0,0.5)" }}
          onClick={onOpen}
          size="xs"
        >
          Add
        </Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent px={20} py={10}>
          <ModalHeader
            textAlign={"center"}
            color={"nevtis.1"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            mb={10}
          >
            Create IVR
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box style={{ width: "100%" }}>
              <Stack spacing={2} width={"full"}>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Name</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Extension</FormLabel>
                  <Input
                    name="extension"
                    value={formData.extension}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Timeout</FormLabel>
                  <Input
                    name="timeout"
                    value={formData.timeout}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel style={formLabelStyle}>Exit Actions</FormLabel>
                  <Select
                    name="actions"
                    onChange={handleInputChange}
                    value={formData.actions}
                  >
                    <option value="">Select an Action</option>
                    <optgroup label="Extensions">
                      {extensionesAsignadas.map((extension, index) => (
                        <option key={index} value={extension}>
                          {extension.extension_number} -{" "}
                          {extension.effective_caller_id_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Ring Groups">
                      {ringGroup.map((group, index) => (
                        <option key={index} value={group}>
                          {group.ring_group_extension} - {group.ring_group_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Fax Servers">
                      {faxServers.map((fax, index) => (
                        <option key={index} value={fax}>
                          {fax.fax_destination_number} - {fax.fax_name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Voicemail">
                      {voicemail.map((mail, index) => (
                        <option key={index} value={mail}>
                          {mail.voicemail_id} - {mail.voicemail_description}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel style={formLabelStyle} mb="0">
                    Enabled
                  </FormLabel>
                  <Switch
                    name="enabled"
                    isChecked={formData.enabled}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel style={formLabelStyle}>Description</FormLabel>
                  <Input
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <Text color="red">{error}</Text>
            </Box>
            <Center mt={10}>
              <Button
                onClick={submit}
                color={"white"}
                bg={"nevtis.1"}
                _hover={{ bg: "nevtis.1.4" }}
              >
                Create
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const formLabelStyle = {
  color: "rgba(255,134,0)",
  fontWeight: "bold",
};

export default CreateIVR;
