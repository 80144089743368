import { Box, Flex, Heading } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Legend,
} from "recharts";

const CallsByExtGraph = ({ softphoneUsers }) => {
  const [calls, setCalls] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formattedDate = (date) => {
      return date.toISOString().split("T")[0];
    };

    const fetchData = async () => {
      try {
        const resp = await axios.post(
          "https://api.nevtis.com/fusionpbx/calls/getCallsByDomainAndDate/f1d51904-3b02-4453-8536-28e7c1e8290a",
          {
            startStamp: formattedDate(today),
            endStamp: formattedDate(tomorrow),
          }
        );
        setCalls(resp.data);
      } catch (error) {
        console.error("Error al obtener las llamadas:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const conteoExtensiones = calls.reduce((conteo, llamada) => {
        const { extension_uuid } = llamada;
        if (extension_uuid) {
          conteo[extension_uuid] = (conteo[extension_uuid] || 0) + 1;
        }
        return conteo;
      }, {});

      const newData = await Promise.all(
        Object.entries(conteoExtensiones).map(
          async ([extension_uuid, count]) => {
            try {
              const resp = await axios.get(
                `https://api.nevtis.com/fusionpbx/extensions/getExtension/${extension_uuid}/resume`
              );
              const user = resp.data[0];
              return {
                name: user
                  ? `${user.effective_caller_id_name} - Ext: ${user.extension}`
                  : "Usuario Desconocido",
                count,
              };
            } catch (error) {
              console.error(
                "Error al obtener el usuario por UUID de extensión:",
                error
              );
              return {
                name: "Usuario Desconocido",
                count,
              };
            }
          }
        )
      );

      setData(newData);
    };

    fetchData();
  }, [calls]);

  const generateUniqueColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const hue = (i * 360) / numColors;
      const color = `hsl(${hue}, 100%, 70%)`;
      colors.push(color);
    }
    return colors;
  };

  const COLORS = generateUniqueColors(data.length);

  return (
    <Box bg={"gray.200"} borderRadius={"2xl"}>
      <Heading color={"nevtis.1"} textAlign={"center"} mb={4} py={1}>
        Calls Today
      </Heading>
      <BarChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fill: "transparent" }} />
        <YAxis tick={{ fill: "black" }} />
        <Tooltip />
        <Bar dataKey="count" fill="#8884d8">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Bar>
      </BarChart>
    </Box>
  );
};

export default CallsByExtGraph;
