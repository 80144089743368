import React from "react";
import { useFetchRecipient } from "./useFetchRecipient";
import { Badge, Box, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { MdFiberManualRecord } from "react-icons/md";
import avatar from "../../assets/profile.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentChat,
  getMessagesByChatId,
} from "../../store/reducers/chat.reducer";
const UserChat = ({ chat }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.chats.notifications);
  const { recipientUser } = useFetchRecipient(chat, user);
  const onlineUsers = useSelector((state) => state.chats.onlineUsers);
  const userIdExists = onlineUsers.some(
    (item) => item.userId === recipientUser?._id
  );
  const messages = [];

  const updtCurrentChat = async (chat) => {
    const currentChat = await dispatch(updateCurrentChat(chat));
    await dispatch(getMessagesByChatId(currentChat.payload._id));
  };

  const ultimoMensaje =
    messages.length > 0 ? messages[messages.length - 1] : null;

  let ultimaFechaCreacion = null;
  let date = null;

  if (ultimoMensaje) {
    ultimaFechaCreacion = new Date(ultimoMensaje.createdAt);
    date = `${(ultimaFechaCreacion.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${ultimaFechaCreacion
      .getDate()
      .toString()
      .padStart(
        2,
        "0"
      )}/${ultimaFechaCreacion.getFullYear()} ${ultimaFechaCreacion
      .getHours()
      .toString()
      .padStart(2, "0")}:${ultimaFechaCreacion
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  function isSenderIdInNotifications(senderId) {
    // Verifica si alguna de las notificaciones tiene el senderId proporcionado
    return notifications.some(
      (notification) => notification.senderId === senderId
    );
  }

  const words = ultimoMensaje?.text.split(" ");
  const truncatedMessage = words?.slice(0, 5).join(" ");
  const finalMessage =
    words?.length > 5 ? truncatedMessage + "..." : truncatedMessage;

  return (
    <VStack spacing="0" align="stretch" onClick={() => updtCurrentChat(chat)}>
      <Box
        bg="transparent"
        cursor="pointer"
        _hover={{ bg: "gray.400" }}
        borderBottom={"1px "}
        borderColor={"nevtis.1"}
        p={2}
      >
        <Flex align="center" justifyContent="space-between">
          <Flex align="center">
            <Box boxSize="30px" mr={2}>
              <Image src={avatar} />
            </Box>

            <Text
              fontWeight={"bold"}
              fontSize={
                isSenderIdInNotifications(recipientUser?._id) ? "lg" : "sm"
              }
              color={"gray.600"}
            >
              {recipientUser?.name}
            </Text>
            {isSenderIdInNotifications(recipientUser?._id) && (
              <Badge ml={2} colorScheme="red">
                New
              </Badge>
            )}
          </Flex>
          <Text fontSize="2xs" color={"black"}>
            {date}
          </Text>

          <Icon
            as={MdFiberManualRecord}
            color={userIdExists ? "green.600" : "red"}
            size={"5px"}
          />
        </Flex>
        <Text fontSize="xs" ml={10} color={"gray.600"}>
          {finalMessage}
        </Text>
      </Box>
    </VStack>
  );
};

export default UserChat;
