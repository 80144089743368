import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
} from "@chakra-ui/react";
import React from "react";
import CreateVoiceMail from "./CreateVoiceMail";

const TableVoiceMail = ({ data }) => {
  return (
    <Box>
      <CreateVoiceMail />
      <TableContainer mt={10} maxH="650px" overflowY="auto">
        <Table size="sm">
          <Thead position="sticky" top={0} zIndex="sticky" bg="white">
            <Tr>
              <Th textAlign={"center"} color={"nevtis.1"}>
                VoiceMail ID
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Mail To
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Transcribe
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Enabled
              </Th>
              <Th textAlign={"center"} color={"nevtis.1"}>
                Description
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((voicemail) => (
              <Tr key={voicemail.dialplan_uuid}>
                <Td textAlign={"center"}>{voicemail.voicemail_id}</Td>
                <Td textAlign={"center"}>{voicemail.voicemail_mail_to}</Td>
                <Td textAlign={"center"}>
                <Badge colorScheme="green">{voicemail.voicemail_transcription_enabled}</Badge>
                  
                </Td>
                <Td textAlign={"center"}>  <Badge colorScheme="green">{voicemail.voicemail_enabled}</Badge></Td>
                <Td textAlign={"center"}>{voicemail.voicemail_description}</Td>
              </Tr>
            ))}

          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableVoiceMail;
